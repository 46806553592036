<!-- Modal Info-->
<div class="modal fade" id="modal-informativa" tabindex="-1" role="dialog" aria-labelledby="modal-informativaLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div class="col-12">
          <h2 class="modal-title boldWhite">{{'INFORMATION_TRAVEL' | translate}}</h2>
          <button type="button" class="close btn-dialog-close-mInfo boldWhite" (click)="modalClosed()" data-dismiss="modal" aria-label="Close">x</button>
        </div>
      </div>
      <div class="modal-body mt-3 mx-3 regularWhite" [innerHTML]="('INFORMATION_TRAVEL_DESC' | translate )">
      </div>
      <div class="d-flex justify-content-center mt-4">
        <div class="custom-control custom-checkbox">
          <mat-checkbox [(ngModel)]="noOpenModalInfo" id="notShowAgain"></mat-checkbox>
          <label class="ml-3 boldWhite mb-0" for="notShowAgain">{{'NOT_SHOW_AGAIN' | translate}}</label>
        </div>
      </div>
      <div class="d-flex justify-content-center my-3">
        <button mat-flat-button color="primary" class="boldWhite" (click)="modalClosed()"
        >{{'OK' | translate | uppercase}}</button>
      </div>
    </div>
  </div>
</div>
