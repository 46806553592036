import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subscriber} from 'rxjs';
import {Constants} from 'src/app/Constants';
import {AccountInfo} from './screenset/account-info';
import {ScreensetParams} from './screenset/screenset-params';
import {UserProfileService} from "../user-profile.service";
import {AuthenticationService} from "../authentication.service";
import {switchMap} from "rxjs/operators";
import {CookieStoragerService} from "../storager/cookie-storager.service";

declare let gigya: any;

@Injectable({
  providedIn: 'root'
})
export class GigyaService {

  constructor(
    private route: Router,
    private ngZone: NgZone,
    private userProfileService: UserProfileService,
    private authenticationService: AuthenticationService,
    private storager: CookieStoragerService,
  ) {
  }

  public showScreenSet(screenset: ScreensetParams) {
    var lang = 'EN'
    if (this.userProfileService.getValue()) {
      lang = this.userProfileService.getValue().LANGUAGE
    } else {
      lang = window.navigator.language.split('-')[0]
    }
    screenset.lang = lang.toLowerCase();
    console.log("Apro il seguente screenset", screenset);
    gigya.accounts.showScreenSet(screenset);
  }

  public getAccountInfo(): Observable<AccountInfo> {
    console.log('Start calling service getAccountInfo using gigya sdk');
    return new Observable<AccountInfo>(observer => {
      gigya.accounts.getAccountInfo({
        include: 'profile,data,preferences,lastLogin',
        extraProfileFields: 'firstName,lastName,address,country,locale,phones,samlData',
        callback: (response) => {
          this.setLogedInState(response, observer);
        }
      });
    });
  }

  public getJWT(usr?): Observable<boolean> {
    return new Observable<boolean>(obs => {
      gigya.accounts.getJWT({
        callback: (response) => {
          this.ngZone.run(() => {
            this.setUserAndAuth(response, usr, obs);
          })
        }
      });
    })
  }

  public getJWTTest(usr?): Observable<boolean> {
    return new Observable<boolean>(obs => {
      gigya.accounts.getJWT({
        callback: (response) => {
          if (response.status == 'FAIL') {
            obs.next(false);
          }
          obs.next(true);
        }
      });
    })
  }

  public checkVerificationDoneFlag(): Observable<boolean> {
    return new Observable<boolean>(o => {
      this.getAccountInfo().subscribe(accInfo => {
        if ((accInfo.data.verificationDone = false || accInfo.data.verificationDone == null || accInfo.data.verificationDone == accInfo.data.verificationDone == null)
          && accInfo.isVerified) {
          gigya.accounts.setAccountInfo({
            data: {
              verificationDone: true
            },
            callback: (res) => {
              if (res.errorCode == 0 && accInfo.data.applications['ProfitbyEducato']) {
                this.userProfileService.setUserObs(accInfo, o);
              } else {
                return o.next(false)
              }
            }
          })
        } else {
          this.userProfileService.setUserObs(accInfo, o);
        }
      })
    });
  }

  setUserAndAuth(gigyaJWT: any, account: AccountInfo, observer: Subscriber<boolean>) {
    if (account) {
      this.authenticationService.authenticate(gigyaJWT.id_token, account).toPromise().then((res) => {
        if (res) {
          return observer.next(true);
        } else {
          return observer.next(false);
        }
      })
    } else {
      return observer.next(false);
    }
  }


  private setLogedInState = (response: AccountInfo, observer: Subscriber<AccountInfo>) => {
    console.log(`Received accountInfo form gigya with value = ${response.status}`);
    return observer.next(response);
  }

  public isLoggedIn(): Observable<boolean> {
    return this.getAccountInfo().pipe(switchMap(res => {
      return this.getJWT(res)
    }));
  }

  public isUserInCookie(): Observable<boolean> {
    return this.getJWTTest();
  }


  public logout() {
    var _this = this;
    gigya.accounts.logout({
      callback: (response) => {
        _this.ngZone.run(() => {
          _this.storager.removeElement('user');
          _this.storager.removeElement('G-STE-USER');
          _this.route.navigate([Constants.Routing.LOGIN.path]);
        })
      }
    });
  }

}

// gigya.accounts.getAccountInfo({
//   include: 'profile,data,preferences,lastLogin',
//   extraProfileFields: 'firstName,lastName,address,country,locale,phones,samlData,privacyFlag',
//   callback: (response) => {
//     console.log(response)
//   }
// });
