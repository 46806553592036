import {AfterViewInit, Component, ElementRef, EventEmitter, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {MapsAPILoader} from "@agm/core";
interface MapPlace {
  address?: string;
  web_site?: string;
  name?: string;
  zip_code?: string;
  lat?: number,
  lng?: number
}

interface WayPoints {
  location?: MapPlace
}

@Component({
  selector: 'app-map-waypoints',
  templateUrl: './map-waypoints.component.html'
})
export class MapWaypointsComponent implements OnInit, AfterViewInit {

  @Output() _totalDistance: EventEmitter<number> = new EventEmitter<number>()
  private totalDistance : number = 0;
  /** Map value */
  lat = 43.902763;
  lng = 11.499257;
  zoom = 6;

  origin: MapPlace = {address: ''}
  destination: MapPlace = {address: ''}
  wayPoints: WayPoints[] = [];
  _wayPointsRoute: WayPoints[] = [];

  checked: boolean = false;
  showDirections = true;

  @ViewChild('mapOrigin') originEleRef: ElementRef;
  @ViewChild('mapWayPointsOne') wayPointsOneEleRef: ElementRef;
  @ViewChild('mapWayPointsTwo') wayPointsTwoEleRef: ElementRef;
  @ViewChild('mapWayPointsThree') wayPointsThreeEleRef: ElementRef;
  @ViewChild('mapWayPointsFour') wayPointsFourEleRef: ElementRef;

  /** Finish Map Value*/

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {
    this._totalDistance.subscribe((res)=> {
      this.totalDistance = res;
    })
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.findAddressOrigin(this.originEleRef);
    this.findAddressOrigin(this.wayPointsOneEleRef);
    this.findAddressOrigin(this.wayPointsTwoEleRef);
    this.findAddressOrigin(this.wayPointsThreeEleRef);
    this.findAddressOrigin(this.wayPointsFourEleRef);
  }


  findAddressOrigin(element: ElementRef) {
    var self = this;
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(element.nativeElement);
      autocomplete.addListener("place_changed", () => {
        self.ngZone.run(() => {
          self.showDirections = false;
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          let searchPlace: MapPlace = {};
          searchPlace.address = place.formatted_address;
          searchPlace.web_site = place.website;
          searchPlace.name = place.name;
          searchPlace.zip_code = place.address_components[place.address_components.length - 1].long_name;
          searchPlace.lat = place.geometry.location.lat();
          searchPlace.lng = place.geometry.location.lng();
          this.setWayPointsOrder(searchPlace, element.nativeElement.id)
        });
      });
    });
  }

  setWayPointsOrder(searchPlace, id) {
    var location = {location: searchPlace}
    switch (id) {
      case 'mapOrigin':
        this.origin = searchPlace
        if(this.checked){
          this.destination = this.origin;
        }
        break;
      case 'mapWayPointsOne':
        this.wayPoints[0] = location
        break;
      case 'mapWayPointsTwo':
        this.wayPoints[1] = location
        break;
      case 'mapWayPointsThree':
        this.wayPoints[2] = location
        break;
      case 'mapWayPointsFour':
        this.wayPoints[3] = location
        break;
    }
    this.wayPointsRoute();
  }

  deleteWayPosition(index) {
    this.showDirections = false;
    this.wayPoints.splice(index, 1);
    this.wayPointsRoute();
  }

  checkSameOriginDestination(event) {
    if (event && this.origin.lat) {
      this.destination = {...this.origin}
    } else {
      this.destination = this.wayPoints[this.wayPoints.length - 1].location;
    }
    this.wayPointsRoute()
  }

  wayPointsRoute() {
    this._wayPointsRoute = [...this.wayPoints]
    if(!this.checked && this._wayPointsRoute.length > 0){
      this.destination = this._wayPointsRoute.pop().location;
    }
    this.showDirectionsMaps()
  }

  calcRoute() {
    var self = this;
    this.mapsAPILoader.load().then(() => {
      var directionsService = new google.maps.DirectionsService();

      let org: google.maps.LatLngLiteral = {lat: this.origin.lat, lng: this.origin.lng};

      let dest: google.maps.LatLngLiteral = {lat: this.destination.lat, lng: this.destination.lng};

      let wyPoints: google.maps.DirectionsWaypoint[] = [];
      for (let i = 0; i < this._wayPointsRoute.length; i++) {
        let point = new google.maps.LatLng(this._wayPointsRoute[i].location.lat, this._wayPointsRoute[i].location.lng)
        wyPoints.push({location: point});
      }
      var request: google.maps.DirectionsRequest = {
        origin: org,
        destination: dest,
        waypoints: wyPoints,
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC
      };

      directionsService.route(request, function (response, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          var total_distance = 0.0;
          for (var i = 0; i < response.routes[0].legs.length; i++) {
            total_distance += response.routes[0].legs[i].distance.value;
          }
          self._totalDistance.emit(Math.round(total_distance / 1000))
        }
      });
    });
  }

  showDirectionsMaps() {
    setTimeout(() => {
      this.calcRoute()
      this.showDirections = true;
    }, 300);
  }

}
