<div>
  <div>
    <div style="display: block">
      <canvas baseChart
              [datasets]="radarChartData"
              [options]="radarChartOptions"
              [labels]="radarChartLabels"
              [chartType]="radarChartType"
              [colors]="colors">
      </canvas>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <b style="color:green">- - -</b>&nbsp;<label class="boldWhite">{{'TARGET_PERFORMANCE' | translate}}</label>
  </div>
  <div class="row justify-content-center mt-2">
    <div *ngFor="let lab of labels " class="d-flex align-items-center justify-content-between my-2">
      <label class="text-white mb-0">{{lab | translate}}</label>
      <button class="d-flex justify-content-end infobtn"
              [matTooltip]="tooltipLabel[lab]"
              aria-label="Div that displays a tooltip when focused or hovered over">
        <div
          class="icon info-icon img-fluid"
          [inlineSVG]="'/assets/icons/info.svg'"
        ></div>
      </button>
    </div>
  </div>
</div>
