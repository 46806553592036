<div class="row justify-content-center align-items-center flex-column">
  <div class="col-12 position-relative">
    <div *ngIf="showMsg" class="container_msg_chart">
      <div class="msg_chart">
        <div>
          <svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="UI-dark" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.5">
              <g id="Dashboard_ViaggiLive_HP_Empty" transform="translate(-502.000000, -1076.000000)"
                 fill="#FFFFFF">
                <g id="Icon-/-Info" transform="translate(502.000000, 1076.000000)">
                  <path
                    d="M10,0 C7.34829244,0 4.80489494,1.05360929 2.92877983,2.92877983 C1.0535883,4.80478999 0,7.34829244 0,10 C0,12.6517076 1.05360929,15.1951051 2.92877983,17.0712202 C4.80478999,18.9464117 7.34829244,20 10,20 C12.6517076,20 15.1951051,18.9463907 17.0712202,17.0712202 C18.9464117,15.19521 20,12.6517076 20,10 C20,7.34829244 18.9463907,4.80489494 17.0712202,2.92877983 C15.19521,1.0535883 12.6517076,0 10,0 Z M10,3.51419995 C10.3460045,3.51419995 10.6780924,3.65194895 10.9232384,3.89628681 C11.1684054,4.14145379 11.3061439,4.47352071 11.3053289,4.82034382 C11.3061451,5.16634831 11.168397,5.49843622 10.9232384,5.74358221 C10.6780714,5.98793056 10.3460045,6.12566907 10,6.12566907 C9.65399551,6.12566907 9.3219076,5.98792007 9.07676161,5.74358221 C8.83159463,5.49841523 8.69385613,5.16634831 8.69467112,4.82034382 C8.69385487,4.47352071 8.83160303,4.1414328 9.07676161,3.89628681 C9.32192859,3.65193846 9.65399551,3.51419995 10,3.51419995 Z M10,7.75907307 C10.6870972,7.75907307 11.2397305,8.3117063 11.2397305,8.99880355 L11.2397305,15.2459436 C11.2397305,15.9330409 10.6870972,16.4856741 10,16.4856741 C9.31290275,16.4856741 8.76026952,15.9330409 8.76026952,15.2459436 L8.76026952,8.99880355 C8.76026952,8.3117063 9.31290275,7.75907307 10,7.75907307 Z"
                    id="Shape"></path>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <h3 class="boldWhite opacity-40">{{'MISSING_GRAPHIC_DATA' | translate }}</h3>
      </div>
    </div>
    <canvas id="canvas" #myPieChart baseChart
            [data]="pieChartData"
            [labels]="pieChartLabels"
            [chartType]="pieChartType"
            [options]="pieChartOptions"
            [colors]="pieChartColors"
            [legend]="pieChartLegend">
    </canvas>
  </div>
</div>
