<div class="mainCard" [ngClass]="{'dashboardCard': cardType == 'dashboardCard'}"
     [ngStyle]="{'border-left-color':  vehicle.colorMainCard}">
  <div class="container">
    <div *ngIf="cardType == 'mainCard'" class="select_mission_card" (click)="saveSimulation()"><span
      class="boldPrimary">{{'SELECT_AND_SAVE' | translate}}</span>&nbsp;&nbsp;<div
      [inlineSVG]="'/assets/icons/star.svg'"></div>
    </div>
    <button class="btn-options-mission" *ngIf="cardType == 'dashboardCard'" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="activeRenameMission()">
        <span>{{'RENAME_MISSION' | translate}}</span>
      </button>
      <button mat-menu-item (click)="deleteMission()">
        <span>{{'DELETE' | translate}}</span>
      </button>
    </mat-menu>
    <div class="row text-center text-md-left">
      <div class="col-12 col-md-4" [ngStyle]="{'padding-right': cardType == 'mainCard' ? '5em': '3em'}">
        <div class="vehicleImage">
          <img class="" [src]="utilityService.imageVehicle(vehicle.bevBodyTypeDescription)" alt=""/>
<!--          <div [ngStyle]="{'height': cardType == 'mainCard' ? '10px': '110px'}"></div>-->
        </div>
        <div class="vehicleSavings" *ngIf="cardType === 'mainCard'">
          <span class="color-white">{{'SAVING_FUEL_COST' | translate}}</span><br/><span
          class="color-primary font-weight-bold"
        >{{vehicle.saving}} {{'PLACEHOLDER_YEAR_COST' | translate}} {{'PER_VEHICLE'|translate}}</span
        >
        </div>
        <div class="vehicleSavings" *ngIf="cardType === 'dashboardCard'">
          <div class="my-3">
            <span [ngClass]="{'critical': vehicle.fitToMissionDesc == 'MISSION_CRITICAL',
               'failed': 'MISSION_FAILED' == vehicle.fitToMissionDesc,
               'success': vehicle.fitToMissionDesc == 'MISSION_COMPLETED'}"
                  class="missionState">{{'MISSION_STATUS' | translate}}
              <span class="font-weight-bold">{{vehicle.fitToMissionDesc | translate}}</span></span><br/>
          </div>
          <div class="vehicleSavings justify-content-start align-items-center">
            <!--            <div class="icon savings-icon mr-2" aria-label="savings-icon" [inlineSVG]="'/assets/icons/savings.svg'"></div>-->
            <span class="color-white">{{'SAVING_FUEL_COST' | translate}}</span>
            <br>
            <span class="boldWhite">{{vehicle.saving}} {{'PLACEHOLDER_YEAR_COST' | translate}}</span>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-8">
        <div class="messagePageTitle mt-2 vehicleName">
          <span>{{ vehicle.bevBodyTypeDescription | translate }} {{vehicle.bevWbDescription | translate}}
            / {{vehicle.bevRoofDescription | translate}}
            <span *ngIf="utilityService.getTypeOfVehicle(vehicle.bevBodyTypeDescription) == 'PEOPLE'">
              {{'SEATS' | translate}}
            </span>
          </span>
          <span *ngIf="vehicle.selected" class="boldWhite ml-2 my-2 selected_vehicle">{{'REQUIRED_SIZE' | translate}}</span>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="color-white" style="line-height: 22px;">
              {{'BATTERY' | translate}} <span class="boldWhite">
              {{vehicle.battery | translate}}</span>
              | {{'SPEED_RECHARGE' | translate}}
              <span
                class="boldWhite">{{vehicle.onboardCharging | translate }}
                - {{vehicle.fullChargingTime}}</span> {{'TO_COMPLETE_RECHARGE' | translate}}
              <br/>
              {{'RECOMMENDED_SPEED' | translate}} <span class="boldWhite">{{vehicle.recommendedSpeed}} km/h</span>
              | {{'LOAD_COMPARTMENT' | translate}}
              <span class="boldWhite"><span *ngIf="vehicle.bevLoadCompartment > 1">{{vehicle.bevLoadCompartment}} m3 - </span> {{vehicle.bevPayload}}
                kg</span> {{'max' | translate}}
            </p>
            <hr/>
            <div class="my-3" *ngIf="cardType === 'mainCard'">
              <span [ngClass]="{'critical': vehicle.fitToMissionDesc == 'MISSION_CRITICAL',
               'failed': 'MISSION_FAILED' == vehicle.fitToMissionDesc, 'success': vehicle.fitToMissionDesc == 'MISSION_COMPLETED'}"
                    class="missionState">{{'MISSION_STATUS' | translate}}
                <span class="font-weight-bold">{{vehicle.fitToMissionDesc | translate}}</span>
              </span><br/>
            </div>
            <div class="row justify-content-between color-white">
              <div class="col-12 col-md-4">
                <span class="missionTitle">{{'RESIDUAL_AUTONOMY' | translate}}</span> <br/>
                <div class="row row justify-content-center align-items-center my-2">
                  <div class="col-2">
                    <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_SUFFICIENT'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/check.svg'"
                    ></div>
                    <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_CRITICAL'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/wave.svg'"
                    ></div>
                    <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_INSUFFICIENT'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/ko.svg'"
                    ></div>
                  </div>
                  <div class="col-10">
                    <span
                      class="missionDetail boldWhite">{{vehicle.residualAutonomy > 0 ? vehicle.residualAutonomy : 0}} km / {{vehicle.autonomy}}
                      km</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <span class="missionTitle">{{'LONG_RECHARGE' | translate}}</span><br/>
                <div class="row justify-content-center align-items-center my-2">
                  <div class="col-2">
                    <div *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_SUFFICIENT'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/check.svg'"
                    ></div>
                    <div
                      *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_INSUFFICIENT' && (vehicle.fitToMissionDesc == 'MISSION_CRITICAL' || vehicle.fitToMissionDesc == 'MISSION_COMPLETED')"
                      class="icon check-icon"
                      aria-label="check-icon"
                      [inlineSVG]="'/assets/icons/wave.svg'"
                    ></div>
                    <div
                      *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_INSUFFICIENT' && vehicle.fitToMissionDesc == 'MISSION_FAILED'"
                      class="icon check-icon"
                      aria-label="check-icon"
                      [inlineSVG]="'/assets/icons/ko.svg'"
                    ></div>
                  </div>
                  <div class="col-10">
                    <span class="missionDetail boldWhite">{{vehicle.lcsDesc| translate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-4">
                <span class="missionTitle">{{'SHORT_RECHARGE' | translate}}</span> <br/>
                <div class="row justify-content-center align-items-center my-2">
                  <div class="col-2">
                    <div *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_UNNECESSARY'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/check.svg'"
                    ></div>
                    <div
                      *ngIf="vehicle.scsDesc == 'FIXED_SHORT_CHARGING_NECESSARY' || vehicle.scsDesc == 'FIXED_SHORT_CHARGING_RECOMMENDED'"
                      class="icon check-icon"
                      aria-label="check-icon"
                      [inlineSVG]="'/assets/icons/wave.svg'"
                    ></div>
                    <div *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_NECESSARY'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/ko.svg'"
                    ></div>
                  </div>
                  <div class="col-10">
                    <span class="missionDetail boldWhite">{{vehicle.scsDesc | translate}} <span
                      *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_NECESSARY'">{{vehicle.scsDuration}}</span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-accordion class="color-white" *ngIf="cardType === 'mainCard'">
    <mat-expansion-panel [expanded]="vehicle.panelIsOpen"
                         (opened)="vehicle.panelIsOpen = true"
                         (closed)="vehicle.panelIsOpen = false"
                         class="expansion-panel color-white"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="boldWhite expansionTitle">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-1">
                <div
                  class="icon energy-icon"
                  aria-label="energy-icon"
                  [inlineSVG]="'/assets/icons/energy.svg'"
                ></div>
              </div>
              <div class="col-11">
                <span>
                  {{'CONFIGURE_OPTIONAL' | translate}}
                </span>
              </div>
            </div>
          </div>
        </mat-panel-title>
        <mat-panel-description></mat-panel-description>
      </mat-expansion-panel-header>
      <div class="row mt-3">
        <div class="col-12 col-md-6">

          <p>{{'UPGRADE_BATTERY' | translate}}</p>
        </div>
        <div class="col-12 col-md-6">
          <div class="checkboxContainer boldWhite">
            <mat-radio-group class="checkboxContainer boldWhite" (change)="changeVehicleVar()"
                             [(ngModel)]="vehicle.checkedBattery">
              <mat-radio-button [value]="47">{{'BATTERY_47_DESC' | translate}}</mat-radio-button>
              &nbsp;
              <mat-radio-button [value]="79">{{'BATTERY_79_DESC' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <p>
            {{'INCREASE_POWER' | translate}}
          </p>
        </div>
        <div class="col-12 col-md-6">
          <div class="checkboxContainer boldWhite">
            <mat-radio-group class="checkboxContainer boldWhite" (change)="changeVehicleVar()"
                             [(ngModel)]="vehicle.checkedCharger">
              <mat-radio-button [disabled]="vehicle.checkedBattery == 79"
                                [value]="6.6">{{'ONBOARD_CHARGING_6_6_FULL_DESC' | translate}}</mat-radio-button>
              &nbsp;
              <mat-radio-button [value]="11">{{'ONBOARD_CHARGING_11_FULL_DESC' | translate}}</mat-radio-button>
              &nbsp;
              <mat-radio-button class="disabled" disabled [value]="22">{{'ONBOARD_CHARGING_22_FULL_DESC' | translate}}</mat-radio-button>
              &nbsp;
              <mat-radio-button [value]="50">{{'ONBOARD_CHARGING_50_FULL_DESC' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
