<div class="slider-range">
  <nouislider
    #rangeSlider
    class="slider-range"
    [config]="rangeConfig"
    [(ngModel)]="startRangeValues"
    (ngModelChange)="onRangeChange($event)"
    (end)="onEndSlide($event)"
  >
  </nouislider>
</div>
<div class="row mb-3 justify-content-center">
  <p class="text-center color-primary"
     [innerHTML]="('DESCRIPTION_CALCULATE_USE_MIX' | translate)">
  </p>&nbsp;
  <p class="boldPrimary"><b>{{speedMed}} {{'km/h' | translate}} </b></p>
</div>
