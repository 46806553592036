import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import {AlgorithmResponse, BevVehicle} from "../../../_models/Algorithm";
import {VehicleService} from "../../../_services/vehicle.service";
import {ChartsService} from "../../../_services/charts.service";


@Component({
  selector: 'scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss']
})
export class ScatterChartComponent implements OnInit {

  @Input() fleetModels: AlgorithmResponse;
  @Output() emitVehicleSelected: EventEmitter<BevVehicle> = new EventEmitter<BevVehicle>();
  private colorMainSelected: string;
  public scatterChartLabels: Label[] = ['Eating', 'Drinking', 'Sleeping', 'Designing', 'Coding', 'Cycling', 'Running'];
  public scatterChartData: ChartDataSets[] = [];
  public scatterChartOptions: ChartOptions = {};
  public scatterChartType: ChartType = 'scatter';

  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  constructor(private vehicleService: VehicleService, private chartsService: ChartsService) {
    this.chartsService._updateChart.subscribe((val) => {
      var self = this;
      if (val) {
        setTimeout(function () {
          self.generateScatterDetails();
          self.changeChartData(self.colorMainSelected);
        }, 250)
      }
    });
  }

  ngOnInit(): void {
    this.generateScatterDetails();
  }

  generateScatterDetails() {
    const self = this;
    this.scatterChartData = [];
    let count = 0;
    let coord = {maxX: 0, minX: 999999999, maxY: 0, minY: 999999999}

    this.fleetModels.bevsPull.forEach((vec) => {

      coord.maxX = vec.totalAutonomy > coord.maxX ? vec.totalAutonomy : coord.maxX;
      coord.minX = vec.totalAutonomy < coord.minX ? vec.totalAutonomy : coord.minX;

      coord.maxY = vec.saving > coord.maxY ? vec.saving : coord.maxY;
      coord.minY = vec.saving < coord.minY ? vec.saving : coord.minY;

      var radius = vec.scsDuration != null ? 8 : 12;
      var borderWidth = 1;
      var borderColor = 'white';

      if (count == 0) {
        borderWidth = 3;
        borderColor = '#ffa500';
      }
      console.log(vec.pallogramId)
      var dataSets = {
        backgroundColor: vec.colorMainCard,
        hoverBackgroundColor: vec.colorMainCard,
        hoverBorderColor: vec.colorMainCard,
        pointBackgroundColor: vec.colorMainCard,
        pointHoverBackgroundColor: vec.colorMainCard,
        pointHoverBorderColor: "white",
        data: [
          {x: vec.totalAutonomy, y: vec.saving},
        ],
        label: vec.pallogramId,
        pointRadius: radius,
        radius: radius,
        hoverRadius: radius,
        borderWidth: borderWidth,
        borderColor: borderColor
      };
      console.log(dataSets)
      self.scatterChartData.push(dataSets);
      count++;
      this.chart.update();
    });

    const interval = setInterval(function () {
      if (count >= self.fleetModels.bevsPull.length) {
        self.generateScatterOptions(coord)
        clearInterval(interval);
      }
    })

  }

  generateCoordForCharts(coord) {
    var coordApp = {...coord}

    coord.maxX = coordApp.maxX + (coordApp.maxX - coordApp.minX)
    coord.minX = coordApp.minX - (coordApp.maxX - coordApp.minX)
    coord.maxY = coordApp.maxY + (coordApp.maxY - coordApp.minY)
    coord.minY = coordApp.minY - (coordApp.maxY - coordApp.minY)

    var lastDigitMaxX = coord.maxX.toString().split("");
    var lastDigitMinX = coord.minX.toString().split("");
    var lastDigitMaxY = coord.maxY.toString().split("");
    var lastDigitMinY = coord.minY.toString().split("");

    coord.maxX = coord.maxX + (10 - parseInt(lastDigitMaxX[lastDigitMaxX.length - 1]));
    coord.minX = coord.minX - parseInt(lastDigitMinX[lastDigitMinX.length - 1]);
    coord.maxY = coord.maxY + (10 - parseInt(lastDigitMaxY[lastDigitMaxY.length - 1]));
    coord.minY = (coord.minY - parseInt(lastDigitMinY[lastDigitMinY.length - 1]));

    return coord;
  }

  generateScatterOptions(coord) {
    coord = this.generateCoordForCharts(coord);
    var options: ChartOptions = {
      responsive: true,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "white",
        bodyFontColor: "black",
        callbacks: {
          label: (item, data) => {
            var toolTip = '';
            data.datasets.forEach((lab) => {
              if (item.xLabel == lab.data[0]['x'] && item.yLabel == lab.data[0]['y']) {
                toolTip = lab.label;
              }
            });
            return toolTip;
          }
        }
      },
      scales: {
        yAxes: [{
          ticks: {fontSize: 13, fontColor: "white", maxTicksLimit: 5, max: coord.maxY, min: coord.minY, display: true},
          gridLines: {display: false},
        }],
        xAxes: [{
          ticks: {fontSize: 13, fontColor: "white", maxTicksLimit: 5, max: coord.maxX, min: coord.minX, display: true},
          gridLines: {color: "white"},
        }]
      },
    };
    this.scatterChartOptions = options;
    this.chart.update();
  }


  public chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
    var indexBev = this.fleetModels.bevsPull.findIndex((bev) => {
      if (bev.colorMainCard == active[0]['_options'].backgroundColor) {
        this.colorMainSelected = bev.colorMainCard;
        this.changeChartData(active[0]['_options'].backgroundColor)
        return true
      }
    });
    this.vehicleService._vehicleSelected.next(this.fleetModels.bevsPull[indexBev]);
    this.emitVehicleSelected.emit(this.fleetModels.bevsPull[indexBev]);
    this.chart.update();
  }


  public chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  changeChartData(color) {
    this.scatterChartData.forEach((chartData) => {
      if (chartData.backgroundColor == color) {
        chartData.borderWidth = 4;
        chartData.borderColor = 'yellow';
      } else {
        chartData.borderWidth = 1;
        chartData.borderColor = 'white';
      }
    });
    this.chart.update();
  }

}
