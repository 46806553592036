import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlgorithmImprovement, BevVehicle} from "../../../_models/Algorithm";
import {AlgorithmService} from "../../../_services/algorithm.service";
import {ChartsService} from "../../../_services/charts.service";
import {SimulatorService} from "../../../_services/simulator.service";
import {MatDialog} from "@angular/material/dialog";
import {UtilityService} from "../../../_services/utility.service";
import {SessionStoragerService} from "../../../_services/storager/session-storager.service";
import {InfoModalComponent} from "../info-modal/info-modal.component";

declare let $: any;
@Component({
  selector: 'side-vehicle-card',
  templateUrl: './side-vehicle-card.component.html',
  styleUrls: ['./side-vehicle-card.component.scss']
})
export class SideVehicleCardComponent {

  modalChecked: boolean= false;
  @Input() heatId: string;
  @Input() vehicle: BevVehicle = new BevVehicle();
  @Input() algorithm: AlgorithmImprovement = new AlgorithmImprovement();
  @Output() _vehicleChanged: EventEmitter<BevVehicle> = new EventEmitter<BevVehicle>();


  constructor(private dialog: MatDialog,private algorithmService: AlgorithmService,
              private chartsService: ChartsService,private simulatorService: SimulatorService,
              public utilityService: UtilityService,
              private storager: SessionStoragerService) {
    this.modalChecked = this.storager.getElement('infoModal');
  }

  changeVehicleVar() {
    if (this.vehicle.checkedBattery == 79) {
      if (this.vehicle.checkedCharger == 6.6) {
        this.vehicle.checkedCharger = 11;
      }
    }
    this.algorithm.battery = this.vehicle.checkedBattery
    this.algorithm.onboardCharging = this.vehicle.checkedCharger;
    this.algorithm.heatId = this.heatId;
    this.algorithm.bevKey = this.vehicle.bevKey;
    this.algorithm.gvw = parseFloat(this.algorithm.gvw);
    this.algorithmService.getElectricModelsSingle(this.algorithm).then((res) => {
      this.vehicle = Object.assign(this.vehicle, res);
    }).catch((err) => console.log('ERROR FIND SINGLE MODEL: ', err))
      .finally(() => {
        this.chartsService._updateChart.next(true);
        this._vehicleChanged.emit(this.vehicle)
      });
  }

  saveSimulation() {
    var body = this.simulatorService.generateBodyForSaveMission(this.algorithm, this.vehicle)
    this.simulatorService.saveSimulatedMission(body).then((res) => {
      this.simulatorService.callSimulatedMission.next(true);
      this.dialog.closeAll();
      this.openModal();
    });
  }


  openModal() {
    var self = this;
    if (!this.modalChecked) {
      setTimeout(function () {
        const dialogRef = self.dialog.open(InfoModalComponent, {
          id: 'journeyInfo'
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
      }, 1000);
    }
  }


}
