<div class="row">
  <div class="col-12 position-relative">
    <div class="form-map">
      <div class="form-map-container">
        <div class="form-input">
          <input matInput #mapOrigin [value]="origin.address" autocorrect="off" [placeholder]="('ADD_ORIGIN' | translate)"
                 autocapitalize="off" spellcheck="off"
                 type="text"
                 style="width: 100%"
                 id="mapOrigin" class="form-control mb-2">
        </div>

        <div class="form-input" [ngStyle]="{'display': origin.address? 'block': 'none'}">
          <input matInput #mapWayPointsOne [value]="wayPoints[0]?.location?.address" placeholder="Aggiungi tappa"
                 autocorrect="off" autocapitalize="off" spellcheck="off"
                 id="mapWayPointsOne"
                 type="text" class="form-control">
          <button mat-mini-fab (click)="deleteWayPosition(0)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div class="form-input" [ngStyle]="{'display': wayPoints[0]?.location?.address ? 'block': 'none'}">
          <input matInput #mapWayPointsTwo [value]="wayPoints[1]?.location?.address" placeholder="Aggiungi tappa"
                 autocorrect="off" autocapitalize="off" spellcheck="off"
                 id="mapWayPointsTwo"
                 type="text" class="form-control">
          <button mat-mini-fab (click)="deleteWayPosition(1)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div class="form-input" [ngStyle]="{'display': wayPoints[1]?.location?.address ? 'block': 'none'}">
          <input matInput [value]="wayPoints[2]?.location?.address" #mapWayPointsThree placeholder="Aggiungi tappa"
                 autocorrect="off" autocapitalize="off" spellcheck="off"
                 id="mapWayPointsThree"
                 type="text" class="form-control">
          <button mat-mini-fab (click)="deleteWayPosition(2)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <div class="form-input" [ngStyle]="{'display': wayPoints[2]?.location?.address ? 'block': 'none'}">
          <input matInput #mapWayPointsFour [value]="wayPoints[3]?.location?.address"
                 autocorrect="off"
                 placeholder="Aggiungi destinazione"
                 autocapitalize="off" spellcheck="off" id="mapWayPointsFour" type="text"
                 class="form-control mt-2">
          <button mat-mini-fab (click)="deleteWayPosition(3)">
            <mat-icon>clear</mat-icon>
          </button>
        </div>
        <mat-checkbox class="mt-2" [(ngModel)]="checked" (ngModelChange)="checkSameOriginDestination($event)">
          {{'DEPARTURE_AND_ARRIVAL' | translate}}
        </mat-checkbox>
      </div>
    </div>
    <agm-map [disableDefaultUI]="true" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
      <agm-direction *ngIf="showDirections"
                     [travelMode]="'DRIVING'"
                     [optimizeWaypoints]="false"
                     [origin]="origin"
                     [destination]="destination"
                     [waypoints]="_wayPointsRoute"></agm-direction>
    </agm-map>
  </div>
<!--  <h1 class="boldWhite">{{totalDistance}}</h1>-->
</div>
