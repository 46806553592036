import { Component, OnInit } from '@angular/core';
import { GigyaService } from 'src/app/_services/gigya/gigya.service';
import {UtilityService} from "../../_services/utility.service";
import {UserProfileService} from "../../_services/user-profile.service";
import {LinksLang} from "../../_models/User";

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  dynamicLinks: LinksLang = new LinksLang();
  constructor(
    private gigya : GigyaService,
    private utilityService: UtilityService,
    private userProfileService: UserProfileService
  ) {
    this.userProfileService.user.subscribe((res) => {
      this.dynamicLinks.dealer_locator = res.dealer_locator;
      this.dynamicLinks.eco_incentives = res.eco_incentives;
      this.dynamicLinks.recharge_locator = res.recharge_locator;
      this.dynamicLinks.terms_conditions = res.terms_conditions;
    });
  }

  ngOnInit(): void {
    this.getlinks()
  }

  logout(){
    this.gigya.logout();
  }
  openNewTab(url: string){
    window.open(url, "_blank")
  }

  getlinks(){
    this.utilityService.getDynamicLinks();
  }

}
