<div mat-dialog-title>
  <h2 class="boldWhite">{{'DETAILS_TRIP_SINGLE_VEHICLE'|translate}}</h2>
  <h4 class="boldPrimary mb-0">{{'CODE_IDENTIFIER'|translate}} {{id |uppercase}}</h4>
<!--  <h4 class="boldPrimary mb-0">{{'CODE_IDENTIFIER'|translate}}</h4>-->
</div>
<mat-dialog-content class="mat-typography">
  <div (click)="closeDialog()" class="btn-dialog-close" [inlineSVG]="'/assets/icons/close-modal.svg'"></div>
  <div class="row">
    <div class="col-12">
      <table class="w-100 mb-3">
        <tr class="tr-header">
          <th class="th-table" *ngFor="let col of displayedColumns">{{col}}</th>
        </tr>
        <tr class="tr-body" *ngFor="let data of dataToView">
          <td>{{data?.createdOn}}</td>
          <td>{{data?.durationStr}}</td>
          <td>{{data?.temperature}}{{'°'}}</td>
          <td>{{data?.averageSpeed}} {{'km/h'}}</td>
          <td>{{data?.distance}} {{'km'}}</td>
        </tr>
      </table>
    </div>
  </div>
</mat-dialog-content>
<div class="row justify-content-center align-items-center">
  <img class="btn-table-prev mr-2" [ngClass]="{'disabled': actualPage <= 1}" (click)="prevPage()"
       src="/assets/icons/left-arrow.svg">
  <h3 class="boldWhite mb-0">{{actualPage}}/{{lastPage}}</h3>
  <img class="btn-table-next ml-2" [ngClass]="{'disabled': actualPage >= lastPage}" (click)="nextPage()"
       src="/assets/icons/left-arrow.svg">
</div>
