// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --test` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {

  production: true,
  keyGMaps: 'AIzaSyDYJ9K8bRQ0IbvIR-E1sePCOCBhSUwRiGs',
  apiUrl: '/dashboard',
  mapsApi: 'https://maps.googleapis.com/maps/api',
  dynamikLinkToapp: 'https://profitbyeducato.com/#/login',
  sapApikey: '3_VcmUsw3Rzy08TOfdcU8ttMRCeytSvbryRKDvwx61yJA1r4bvd0H3SFDHaTN9p6k4',
  gigya : `https://cdns.eu1.gigya.com/js/gigya.js?apikey=3_VcmUsw3Rzy08TOfdcU8ttMRCeytSvbryRKDvwx61yJA1r4bvd0H3SFDHaTN9p6k4&regSource=ProFit-byeDucato_FiatPRO`,
  privacyLink : '',
  privacyDisclaimerJson: 'profit-privacy-disclaimers_prod.json',
  termsLink: '',
  idpBroker:{
    user: 'c7b26c79-0b0c-4085-8803-faa5048bc2ef\t',
    key: 'nR6uK4cW7cU0bL0fB2aS3jQ5sF3aE5cF2aL7tB6mY2hA3nP1fK',
    link: 'https://api02-emea.fcagroup.com/emea/extra/IdPBroker/b2c/v1/users/'
  }

};



