import {Injectable} from '@angular/core';
import {Storager} from './storager';
import {CookieService} from 'ngx-cookie-service';
import {isNullOrUndefined} from 'util';


@Injectable({
  providedIn: 'root'
})
export class CookieStoragerService implements Storager {

  constructor(
    private cookie: CookieService
  ) {
  }

  setElement(key: string, obj: any, encoded?: boolean, expires?: Date, path?: string, domain?: string, secure?: boolean, sameSite?: 'Lax' | 'Strict' | 'None') {
    var jsonObj = JSON.stringify(obj);
    if (encoded) {
      jsonObj = btoa(jsonObj);
    }
    this.cookie.set(key, jsonObj, expires, path, domain, secure, sameSite);
  }

  getElement(key: string, encoded?: boolean) {
    var element = this.cookie.get(key);
    if (element) {
      if (encoded) {
        element = atob(element);
      }
      return JSON.parse(element);
    }
    return null;
  }

  removeElement(key: string) {
    this.cookie.delete(key);
  }

  clearAll() {
    this.cookie.deleteAll();
  }

  hasElement(key: string): boolean {
    return this.cookie.check(key);
  }

}
