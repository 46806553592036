<div class="navbar" *ngIf="true">
  <div class="container">
    <div class="row navbarContent h-100 align-items-center">
      <div class="col-8 col-lg-2 logoContainer">
        <div class="row logoBurgerContainer">
          <button class="d-md-none hamburger">
            <img class="van-icon img-fluid" src="/assets/icons/van.svg" />
          </button>
          <a routerLink="/#" routerLinkActive="active">
            <div class="icon logo-icon img-fluid" aria-label="logo-icon" [inlineSVG]="'/assets/icons/logo.svg'"></div>
          </a>
        </div>
      </div>
      <div class="col-md-8 d-none d-lg-flex align-items-center justify-content-around navLinks">
        <div class="row align-items-center" *ngIf="dynamicLinks.terms_conditions != ''">
          <div class="navbarLink ecoincentivi cursor-pointer" (click)="openNewTab(dynamicLinks.terms_conditions)">
            {{ "TERMS_CONDITION" | translate | uppercase }}
          </div>
          <div class="icon link-icon img-fluid ml-2 cursor-pointer" aria-label="link-icon"
            [inlineSVG]="'/assets/icons/link.svg'" (click)="openNewTab(dynamicLinks.terms_conditions)"></div>
        </div>
        <div class="row align-items-center" *ngIf="dynamicLinks.eco_incentives != ''">
          <div class="navbarLink ecoincentivi cursor-pointer" (click)="openNewTab(dynamicLinks.eco_incentives)">
            {{ "ECO-INCENTIVES" | translate | uppercase }}
          </div>
          <div class="icon link-icon img-fluid ml-2 cursor-pointer" aria-label="link-icon"
            [inlineSVG]="'/assets/icons/link.svg'" (click)="openNewTab(dynamicLinks.eco_incentives)"></div>
        </div>
        <div class="row align-items-center" *ngIf="dynamicLinks.recharge_locator != ''">
          <div class="navbarLink ricarica cursor-pointer" (click)="openNewTab(dynamicLinks.recharge_locator)">
            {{ "CHARGE_COLUMN" | translate | uppercase }}
          </div>
          <div class="icon link-icon img-fluid ml-2 cursor-pointer" aria-label="link-icon"
            [inlineSVG]="'/assets/icons/link.svg'" (click)="openNewTab(dynamicLinks.recharge_locator)"></div>
        </div>
        <div class="row align-items-center dealer" *ngIf="dynamicLinks.dealer_locator != ''">
          <button mat-flat-button color="primary" class="dealerBtn navbarLink  cursor-pointer"
            (click)="openNewTab(dynamicLinks.dealer_locator)">
            {{ "CONTACT_DEALER" | translate | uppercase }}
          </button>
        </div>
      </div>
      <div class="col-4 col-lg-2 d-flex justify-content-end profileIcon">
        <button mat-button [matMenuTriggerFor]="menu">
          <div class="icon user-icon img-fluid" aria-label="user-icon" [inlineSVG]="'/assets/icons/user.svg'"></div>
        </button>
        <mat-menu #menu="matMenu" class="menu">
          <span mat-menu-item>
            <div class="row px-3 justify-content-center align-items-center" [routerLink]="'/profile-update'">
              <div>{{ "REGISTRY" | translate }}</div>
              <div class="icon profile-icon img-fluid ml-3" aria-label="profile-icon"
                [inlineSVG]="'/assets/icons/profile.svg'"></div>
            </div>
          </span>
          <hr />
          <span mat-menu-item>
            <span (click)="logout()" class="d-flex justify-content-end">{{"LOGOUT" | translate | uppercase}}</span>
          </span>
        </mat-menu>
      </div>
    </div>
  </div>
</div>