import {Component, DoCheck, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from "chart.js";
import {BaseChartDirective, Color, Label} from "ng2-charts";
import {Observable} from "rxjs";
import * as pluginAnnotations from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  // styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {
  allChartData = [];
  @Input() chartData: any = {};
  tabsActive: string = 'cf';
  lineChartLabels: Label[] = [];
  successPercentage: any = [];
  bevCostSum: any = [];
  heatCostSum: any = [];
  showMsg: boolean = false;
  indexChart: number = 0;

  private successOptions = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            fontSize: 25,
            max: 100
          }
        }
      ]
    }
  };

  private bevHeatOptions = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [
        {
          id: 'y-axis-0',
          position: 'left',
          ticks: {
            min: 0,
            fontSize: 25,
          }
        }
      ]
    }
  };

  public lineChartData: ChartDataSets[] = [];

  public lineChartOptions: ChartOptions = this.bevHeatOptions;
  public lineChartColors: Color[] = []

  colorBevCostSum = { // grey
    backgroundColor: 'rgba(114,228,255,0.35)',
    borderColor: 'rgba(114,228,255,0.55)',
    pointBackgroundColor: 'rgba(255,255,255,0.5)',
    pointBorderColor: '#ffffff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  };

  colorHeatCostSum = { // grey
    backgroundColor: 'rgba(0,98,151,0.35)',
    borderColor: 'rgba(0,98,151,0.55)',
    pointBackgroundColor: 'rgba(255,255,255,0.5)',
    pointBorderColor: '#ffffff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  };

  colorSuccessPercentage = {
    backgroundColor: 'rgba(150,155,233,0.7)',
    borderColor: 'rgba(150,155,233,0.8)',
    pointBackgroundColor: 'rgba(255,255,255,0.5)',
    pointBorderColor: '#ffffff',
    pointHoverBackgroundColor: '#fff',
    pointHoverBorderColor: 'rgba(148,159,177,0.8)'
  };
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  constructor() {
  }

  ngOnInit(): void {
    let _this = this;
    this.subdivisionData(this.chartData).subscribe((res) => {
      if (res) {
        _this.initDataForChart(_this.allChartData[_this.indexChart]).subscribe((res) => {
          if (res) {
            _this.chart.update(1000);
          }
        });
      }
    });
  }

  initDataForChart(data): Observable<boolean> {
    return new Observable<boolean>(o => {
      this.bevCostSum = [];
      this.heatCostSum = [];
      this.successPercentage = []
      var a = Object.entries(data);
      if (a.length > 0 && a.length < 6) {
        data = this.setPreviousDate(data)
      }
      this.lineChartLabels = Object.keys(data).reverse();
      this.lineChartLabels.forEach((key) => {
        // @ts-ignore
        this.successPercentage.push(data[key].successPercentage);
        // @ts-ignore
        this.bevCostSum.push(data[key].bevCostSum);
        // @ts-ignore
        this.heatCostSum.push(data[key].heatCostSum);
      });
      this.lineChartData = [
        {data: this.bevCostSum, lineTension: 0, label: 'Bev Cost Sum'},
        {data: this.heatCostSum, lineTension: 0, label: 'Heat Cost Sum'},
      ];
      this.lineChartColors.push(this.colorBevCostSum);
      this.lineChartColors.push(this.colorHeatCostSum);
      this.showMsg = this.lineChartData.length <= 0;

      o.next(true);
    });
  }

  startChangeActivity(type): Observable<boolean> {
    return new Observable<boolean>(o => {
      this.lineChartColors = [];
      this.lineChartData = [];
      switch (type) {
        case 'cf':
          this.lineChartOptions = this.bevHeatOptions;
          // aggiungere chiamata per i dati
          this.lineChartData = [
            {data: this.bevCostSum, lineTension: 0, label: 'Bev Cost Sum'},
            {data: this.heatCostSum, lineTension: 0, label: 'Heat Cost Sum'},
          ];
          this.lineChartColors.push(this.colorBevCostSum);
          this.lineChartColors.push(this.colorHeatCostSum);
          this.tabsActive = 'cf'
          break
        case 'cm':
          this.lineChartOptions = this.successOptions;
          // aggiungere chiamata per i dati
          this.lineChartData = [
            {data: this.successPercentage, lineTension: 0, label: 'Success Percentage'},
          ];
          this.lineChartColors.push(this.colorSuccessPercentage);
          this.tabsActive = 'cm';
          break
      }
      o.next(true);
    });
  }

  subdivisionData(chartData) {
    return new Observable<boolean>(o => {
      let count = 0;
      let chartsWeek = {};
      let index = 0;
      for (let key of Object.keys(chartData).reverse()) {
        if (count > 6) {
          chartsWeek = {};
          index++;
          chartsWeek[key] = chartData[key];
          this.allChartData[index] = chartsWeek;
          count = 0;
        } else {
          chartsWeek[key] = chartData[key];
          this.allChartData[index] = chartsWeek;
        }
        count++
      }
      this.allChartData = this.allChartData.reverse();
      this.indexChart = this.allChartData.length - 1;
      o.next(true);
    });
  }

  changeActivity(type) {
    this.startChangeActivity(type).subscribe((res) => {
      if (res) {
        this.chart.update(1000);
      }
    })
  }

  changeChartDays(type: string) {
    let _this = this;
    switch (type) {
      case 'next':
        _this.indexChart++;
        console.log(_this.indexChart);
        _this.initDataForChart(_this.allChartData[_this.indexChart]).subscribe((res) => {
          if (res) {
            _this.changeActivity(_this.tabsActive);
          }
        });
        break;
      case 'prev':
        _this.indexChart--;
        console.log(_this.indexChart);
        _this.initDataForChart(_this.allChartData[_this.indexChart]).subscribe((res) => {
          if (res) {
            _this.changeActivity(_this.tabsActive);
          }
        });
        break
    }
  }

  setPreviousDate(res) {
    var obj = {...res}
    var keys = Object.entries(res)
    res = {};
    for (let key of keys) {
      res[key[0]] = obj[key[0]];
    }
    res[' '] = {
      heatCostSum: 0,
      bevCostSum: 0,
      successPercentage: 0
    };

    return res
  }
}


