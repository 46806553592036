<mat-dialog-content mat-dialog-content>
  <div class="icon d-flex justify-content-center" [inlineSVG]="'/assets/icons/info.svg'"></div>
  <br>
  <h3 class="boldWhite text-center">
    {{message | translate}}
    <br>
<!--    {{'WANT_CONTINUE' | translate}}-->
  </h3>
  <br>
</mat-dialog-content>
<mat-dialog-actions class="row justify-content-center">
  <div class="col-5">
    <button class="btn-decline boldPrimary" mat-button [mat-dialog-close]="true">{{'NO_DECLINE' | translate}}</button>
  </div>
  <div class="col-5">
    <button class="btn-continue boldWhite" mat-button (click)="startEvent()">{{'YES_CONTINUE' | translate}}</button>
  </div>
</mat-dialog-actions>
