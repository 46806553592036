import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AlgorithmImprovement, BevVehicle} from "../_models/Algorithm";
import {Mission} from "../_models/Mission";
import {Subject} from "rxjs";
import {UserProfileService} from "./user-profile.service";


@Injectable({
  providedIn: 'root'
})
export class SimulatorService {
  callSimulatedMission: Subject<boolean> = new Subject<boolean>();
  callSimulatedMission$ = this.callSimulatedMission.asObservable();

  missionToEdit: Subject<string> = new Subject<string>();
  missionToEdit$ = this.missionToEdit.asObservable();

  private vatNumber: string;

  constructor(private http: HttpClient, private userService: UserProfileService) {
  }

  getSimulatedtMission(): Promise<Mission[]> {
    let vatN = this.userService.getVatNumber()
    return this.http.get<Mission[]>(`${environment.apiUrl}/simulator/mission/${vatN}`).toPromise()
  }

  saveSimulatedMission(body): Promise<any> {
    let vatN = this.userService.getVatNumber()
    return this.http.post<any>(`${environment.apiUrl}/simulator/mission`, body).toPromise()
  }

  deleteMission(id): Promise<any> {
    let vatN = this.userService.getVatNumber()
    return this.http.delete<any>(`${environment.apiUrl}/simulator/mission/${vatN}/${id}`).toPromise()
  }

  putMission(mission: Mission): Promise<any> {
    let vatN = this.userService.getVatNumber()
    return this.http.put<any>(`${environment.apiUrl}/simulator/mission/${vatN}/${mission.ID}`, {name: mission.name}).toPromise()

  }

  generateBodyForSaveMission(alg: AlgorithmImprovement, vehicle: BevVehicle): any {
    let vatN = this.userService.getVatNumber()
    var body = {
      vatNumber: vatN,
      vehicleGroup: {
        configName: alg.configName,
        modelCode: alg.modelCode,
        wb: alg.wb,
        roof: alg.roof,
        gvw: parseFloat(alg.gvw),
        bodyType: alg.bodyType,
        body: alg.body
      },
      input: {
        heatId: alg.heatId,
        bevKey: alg.bevKey,
        weekWorkingDays: alg.weekWorkingDays,
        yearWorkingWeeks: alg.yearWorkingWeeks,
        fuelDieselCost: alg.fuelDieselCost,
        fuelBevCost: alg.fuelBevCost,
        temperature: alg.temperature,
        payload: alg.payload,
        km: alg.km,
        shortChargingSlot: alg.shortChargingSlot,
        longChargingSlot: alg.longChargingSlot,
        battery: alg.battery,
        onboardCharging: alg.onboardCharging,
        urbanUse: alg.urbanUse,
        extraurbanUse: alg.extraurbanUse,
        highwayUse: alg.highwayUse,
        avgPayload: alg.avgPayload
      },
      output: {
        bevId: vehicle.bevId,
        bevKey: vehicle.bevKey,
        bevWbDescription: vehicle.bevWbDescription,
        bevRoofDescription: vehicle.bevRoofDescription,
        bevBodyTypeDescription: vehicle.bevBodyTypeDescription,
        bevLoadCompartment: vehicle.bevLoadCompartment,
        bevPayload: vehicle.bevPayload,
        selected: vehicle.selected,
        battery: vehicle.battery,
        onboardCharging: vehicle.onboardCharging,
        fullChargingTime: vehicle.fullChargingTime,
        recommendedSpeed: vehicle.recommendedSpeed,
        residualAutonomy: vehicle.residualAutonomy,
        autonomy: vehicle.autonomy,
        totalAutonomy: vehicle.totalAutonomy,
        saving: vehicle.saving,
        payloadIndex: vehicle.payloadIndex,
        taIndex: vehicle.taIndex,
        avgCommSpeedIndex: vehicle.avgCommSpeedIndex,
        lcsIndex: vehicle.lcsIndex,
        scsIndex: vehicle.scsIndex,
        savingIndex: vehicle.savingIndex,
        autonomyDesc: vehicle.autonomyDesc,
        fitToMissionDesc: vehicle.fitToMissionDesc,
        lcsDesc: vehicle.lcsDesc,
        scsDesc: vehicle.scsDesc,
        scsDuration: vehicle.scsDuration,
        performanceIndex: vehicle.performanceIndex
      }
    }
    return body;
  }
}
