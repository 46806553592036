import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {BevVehicle} from "../_models/Algorithm";


@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  public _vehicleSelected = new Subject<BevVehicle>();
  private vehicleSelected : BevVehicle = new BevVehicle();
  constructor() {
  }

  setVehicleSelected(vec: BevVehicle){
    this._vehicleSelected.next(this.vehicleSelected);
  }

}
