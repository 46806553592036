<p class="sectionLabel">{{'PERSONAL_DATA' | translate | uppercase}}</p>
<div class="card">
  <div class="row">
    <div class="col-md-6">
      <div class="inputContainer m-10">
        <div class="fieldLabel row">
          <div class="col-6"><label>{{'NEED_NAME' | translate}}</label></div>

        </div>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="inputContainer m-10">
        <label>{{'NEED_SURNAME' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4 inputContainer">
      <label>{{'NEED_NUMBER_PREFIX' | translate}}</label><br>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select>
          <mat-option *ngFor="let prefix of prefixes" [value]="prefix.value">
            {{ prefix.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_PHONE_NUMBER' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label class="color-primary">{{'NEED_CAP' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_COUNTRY' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_LANGUAGE' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="checkboxContainer m-10">
        <label>{{'NEED_LICENSE' | translate}}</label> <br />
        <mat-checkbox>{{'LICENSE_B' | translate}}</mat-checkbox>
        <mat-checkbox class="ml-2">{{'LICENSE_C_OR_PLUS' | translate}}</mat-checkbox>
      </div>
    </div>
  </div>
</div>

<p class="sectionLabel">{{'COMPANY_DATA' | translate | uppercase}}</p>
<div class="card">
  <div class="row">
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_COMPANY_NAME' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_AREA' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_PT_IVA' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
  </div>
</div>

<p class="sectionLabel">{{'ACCOUNT_DATA' | translate | uppercase}}</p>
<div class="card">
  <div class="row">
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_EMAIL' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_PASSWORD' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
    <div class="col-md-4">
      <div class="inputContainer m-10">
        <label>{{'NEED_CONFIRM_PASSWORD' | translate}}</label>
        <input
          type="text"
          class="formInput w-100"
          placeholder="{{'INSERT_TEXT' | translate}}"
        />
      </div>
    </div>
  </div>
</div>
