import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable, Subject} from "rxjs";
import {UserProfileService} from "./user-profile.service";
import {LiveTrip} from "../_models/LiveTrip";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class LivetripService {
  callGetLiveTrip: Subject<boolean> = new Subject<boolean>();
  callGetLiveTrip$ = this.callGetLiveTrip.asObservable();

  liveTrip: Subject<LiveTrip[]> = new Subject<LiveTrip[]>();
  liveTrip$ = this.liveTrip.asObservable();

  public vatNumber = this.userService.getVatNumber();

  constructor(private http: HttpClient, private userService: UserProfileService) {
  }

  getLiveTrip(): Promise<LiveTrip[]> {
    return this.http.get<LiveTrip[]>(`${environment.apiUrl}/live/mission/${this.vatNumber}`).toPromise()
  }

  saveLiveTrip(body): Promise<any> {
    body.vatNumber = this.vatNumber
    return this.http.post<any>(`${environment.apiUrl}/live/mission`, body).toPromise()
  }

  deleteLiveTrip(trip: LiveTrip): Promise<any> {
    return this.http.delete<any>(`${environment.apiUrl}/live/mission/${this.vatNumber}/${trip.ID}`).toPromise()
  }

  putLiveTrip(trip: LiveTrip): Promise<any> {
    return this.http.put<any>(`${environment.apiUrl}/live/mission/${this.vatNumber}/${trip.ID}`, trip).toPromise()

  }

  getLiveTripCode(): Observable<Blob> {
    var application = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    return this.http.get(`${environment.apiUrl}/live/activation/${this.vatNumber}`, {
      responseType: 'blob',
      headers:{
        'Content-Type':  application,
        Accept: application
      }
    });
  }

  /** Start Live Stats API */

  getLiveStatsJourneys(vecCode): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/live-stats/journeys/${this.vatNumber}/${vecCode}`).toPromise()
  }

  getLiveAggregate(): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/live-stats/aggregate/${this.vatNumber}`).pipe((map((res) => {
      if (!res.stats) {
        res.stats = {}
      }
      if (!res.chart) {
        res.chart = {}
      } else {
        var a = Object.entries(res.chart);
        if (a.length > 0 && a.length < 3) {
          res = this.setPreviousDate(res)
        }
      }
      return res;
    }))).toPromise();
    // return this.http.get<any>(`${environment.apiUrl}/live-stats/aggregate/${this.vatNumber}`).toPromise()
  }

  getLiveAggregateMission(missionId): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/live-stats/aggregate/${this.vatNumber}/${missionId}`).pipe((map((res) => {
      if (!res.stats) {
        res.stats = {}
      }
      if (!res.chart) {
        res.chart = {}
      }
      // res.chart = this.charts
      return res;
    }))).toPromise()
  }

  getLiveAggregateGroup(missionId, vehicleGroupId): Promise<any> {
    return this.http.get<any>(`${environment.apiUrl}/live-stats/aggregate/${this.vatNumber}/${missionId}/${vehicleGroupId}`).toPromise()
  }

  saveAlternative(body) {
    body.vatNumber = this.vatNumber;
    return this.http.post<any>(`${environment.apiUrl}/live/vehicle/choice`, body).toPromise()
  }

  /** End Live Stats API */

  setPreviousDate(res) {
    var obj = {...res.chart}
    var keys = Object.entries(res.chart)
    res.chart = {};
    res.chart[' '] = {
      heatCostSum: 0,
      bevCostSum: 0,
      successPercentage: 0
    };
    for (let key of keys) {
      res.chart[key[0]] = obj[key[0]];
    }
    return res
  }

  private charts = {
    '02-04-2020': {
      bevCostSum: 2.59,
      heatCostSum: 11.4,
      successPercentage: 66
    },
    '03-04-2020': {
      bevCostSum: 2.79,
      heatCostSum: 14.4,
      successPercentage: 80
    },
    '04-04-2020': {
      bevCostSum: 3.49,
      heatCostSum: 10.4,
      successPercentage: 33
    },
    '05-04-2020': {
      bevCostSum: 4.64,
      heatCostSum: 16.4,
      successPercentage: 44
    },
    '06-04-2020': {
      bevCostSum: 3.59,
      heatCostSum: 14.4,
      successPercentage: 55
    },
    '07-04-2020': {
      bevCostSum: 4.88,
      heatCostSum: 15.4,
      successPercentage: 66
    },
    '08-04-2020': {
      bevCostSum: 2.75,
      heatCostSum: 9.4,
      successPercentage: 88
    },
    '09-04-2020': {
      bevCostSum: 3.54,
      heatCostSum: 14.4,
      successPercentage: 55
    },
    '10-04-2020': {
      bevCostSum: 1.48,
      heatCostSum: 7.4,
      successPercentage: 38
    },
    '11-04-2020': {
      bevCostSum: 4.56,
      heatCostSum: 17,
      successPercentage: 76
    },
    '12-04-2020': {
      bevCostSum: 2.59,
      heatCostSum: 11.4,
      successPercentage: 66
    },
    '13-04-2020': {
      bevCostSum: 2.79,
      heatCostSum: 14.4,
      successPercentage: 80
    },
    '14-04-2020': {
      bevCostSum: 3.49,
      heatCostSum: 10.4,
      successPercentage: 33
    },
    '15-04-2020': {
      bevCostSum: 4.64,
      heatCostSum: 16.4,
      successPercentage: 44
    },
    // '16-04-2020': {
    //   bevCostSum: 3.59,
    //   heatCostSum: 14.4,
    //   successPercentage: 55
    // },
    // '17-04-2020': {
    //   bevCostSum: 4.88,
    //   heatCostSum: 15.4,
    //   successPercentage: 66
    // },
    // '18-04-2020': {
    //   bevCostSum: 2.75,
    //   heatCostSum: 9.4,
    //   successPercentage: 88
    // },
    // '19-04-2020': {
    //   bevCostSum: 3.54,
    //   heatCostSum: 14.4,
    //   successPercentage: 55
    // },
    // '20-04-2020': {
    //   bevCostSum: 1.48,
    //   heatCostSum: 7.4,
    //   successPercentage: 38
    // },
    // '21-04-2020': {
    //   bevCostSum: 4.56,
    //   heatCostSum: 17,
    //   successPercentage: 76
    // },
    // '22-04-2020': {
    //   bevCostSum: 4.56,
    //   heatCostSum: 17,
    //   successPercentage: 76
    // },
    // '23-04-2020': {
    //   bevCostSum: 2.59,
    //   heatCostSum: 11.4,
    //   successPercentage: 66
    // },
    // '24-04-2020': {
    //   bevCostSum: 2.79,
    //   heatCostSum: 14.4,
    //   successPercentage: 80
    // },
    // '25-04-2020': {
    //   bevCostSum: 3.49,
    //   heatCostSum: 10.4,
    //   successPercentage: 33
    // },
    // '26-04-2020': {
    //   bevCostSum: 4.64,
    //   heatCostSum: 16.4,
    //   successPercentage: 44
    // },
    // '27-04-2020': {
    //   bevCostSum: 3.59,
    //   heatCostSum: 14.4,
    //   successPercentage: 55
    // },
    // '28-04-2020': {
    //   bevCostSum: 4.88,
    //   heatCostSum: 15.4,
    //   successPercentage: 66
    // },
    // '29-04-2020': {
    //   bevCostSum: 2.75,
    //   heatCostSum: 9.4,
    //   successPercentage: 88
    // },
    // '30-04-2020': {
    //   bevCostSum: 3.54,
    //   heatCostSum: 14.4,
    //   successPercentage: 55
    // },
    // '31-04-2020': {
    //   bevCostSum: 1.48,
    //   heatCostSum: 7.4,
    //   successPercentage: 38
    // },
    // '01-05-2020': {
    //   bevCostSum: 4.56,
    //   heatCostSum: 17,
    //   successPercentage: 76
    // },
    // '02-05-2020': {
    //   bevCostSum: 2.59,
    //   heatCostSum: 11.4,
    //   successPercentage: 66
    // },
    // '03-05-2020': {
    //   bevCostSum: 2.79,
    //   heatCostSum: 14.4,
    //   successPercentage: 80
    // },
    // '04-05-2020': {
    //   bevCostSum: 3.49,
    //   heatCostSum: 10.4,
    //   successPercentage: 33
    // },
    // '05-05-2020': {
    //   bevCostSum: 4.64,
    //   heatCostSum: 16.4,
    //   successPercentage: 44
    // },
    // '06-05-2020': {
    //   bevCostSum: 3.59,
    //   heatCostSum: 14.4,
    //   successPercentage: 55
    // },
    // '07-05-2020': {
    //   bevCostSum: 4.88,
    //   heatCostSum: 15.4,
    //   successPercentage: 66
    // },
    // '08-05-2020': {
    //   bevCostSum: 2.75,
    //   heatCostSum: 9.4,
    //   successPercentage: 88
    // },
    // '09-05-2020': {
    //   bevCostSum: 3.54,
    //   heatCostSum: 14.4,
    //   successPercentage: 55
    // },
    // '10-05-2020': {
    //   bevCostSum: 1.48,
    //   heatCostSum: 7.4,
    //   successPercentage: 38
    // },
    // '11-05-2020': {
    //   bevCostSum: 4.56,
    //   heatCostSum: 17,
    //   successPercentage: 76
    // },
    // '12-05-2020': {
    //   bevCostSum: 4.56,
    //   heatCostSum: 17,
    //   successPercentage: 76
    // }
  }
}
