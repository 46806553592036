<div>
  <div>
    <div
      class="color-primary smallText mb-3">{{'SAVING_FUEL_COST' | translate}} ({{'PLACEHOLDER_YEAR_COST' | translate}})</div>
    <div style="display: block">
      <canvas baseChart
              [datasets]="scatterChartData"
              [options]="scatterChartOptions"
              [labels]="scatterChartLabels"
              [chartType]="scatterChartType"
              (chartClick)="chartClicked($event)">
      </canvas>
    </div>
    <div class="color-primary smallText mt-2 d-flex justify-content-end">{{'AUTONOMY' | translate }} (%)</div>

  </div>
</div>
