import {Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SpinnerService} from './shared/components/spinner/spinner.service';
import {GigyaService} from './_services/gigya/gigya.service';
import {LocaleService} from "./_services/locale.service";
import {UserProfileService} from "./_services/user-profile.service";
import {DefaultLangChangeEvent} from "@ngx-translate/core/lib/translate.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ev-ready-frontend';

  constructor(
    private localService: LocaleService,
    private spinner: SpinnerService,
    private router: Router,
    private userProfileService: UserProfileService,
    private translate: TranslateService
  ) {
    this.setInitiLang()
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  public loading: boolean;

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  setInitiLang() {
    var lang = 'EN'
    if (this.userProfileService.getValue()) {
      lang = this.userProfileService.getValue().LANGUAGE
    } else {
      lang = window.navigator.language.split('-')[0]
    }
    this.localService.configureLanguage(lang.toUpperCase());
  }

}

