import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SpinnerService} from 'src/app/shared/components/spinner/spinner.service';
import {catchError, map} from "rxjs/operators";


@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  count = 0;

  constructor(
    private spinner: SpinnerService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.increase()
    return next.handle(req)
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.decrease()
        }
        return evt;
      }))
      .pipe(catchError((err) => {
        this.decrease()
        return err;
      }));
  }

  private increase() {
    this.spinner.show();
    // this.count++;
  }

  private decrease() {
    this.spinner.hide()
    // this.count--;
    // if (this.count == 0){
    // this.spinner.hide()
    // }
  }
}
