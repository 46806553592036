import {Injectable} from "@angular/core";
import {Subject} from "rxjs";
import {BevVehicle} from "../_models/Algorithm";


@Injectable({
  providedIn: 'root'
})
export class ChartsService {

  public _updateChart = new Subject<boolean>();
  constructor() {
  }

}
