import {AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {BevVehicle} from "../../../_models/Algorithm";
import {MatDialog} from "@angular/material/dialog";
import {SimulatorService} from "../../../_services/simulator.service";
import {ModalActionComponent} from "../modal-action/modal-action.component";
import {ModalAlternativesComponent} from "../modal-alternatives/modal-alternatives.component";
import {LivetripService} from "../../../_services/livetrip.service";
import {UtilityService} from "../../../_services/utility.service";

@Component({
  selector: 'vehicle-group-view',
  templateUrl: './vehicle-group-view.component.html',
  styleUrls: ['./vehicle-group-view.component.scss']
})
export class VehicleGroupViewComponent {
  @Input() idMission: string = '';
  @Input() vehicleCode: string = '';
  @Input() vehicle: any = {};
  @Input() cardType: string = '';
  @Input() bevsPull: BevVehicle[] = [];
  @Input() background: string = null;
  @Input() showSave: boolean = false;


  constructor(public dialog: MatDialog, private livetripService: LivetripService,
              public utilityService: UtilityService) {

  }

  showAlternatives() {
    var dialogRef = this.dialog.open(ModalAlternativesComponent, {
      data: {bevsPull: this.bevsPull, idMission: this.idMission, vehicleCode: this.vehicleCode}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }


  openDialog(type) {
    var dialogRef = this.dialog.open(ModalActionComponent, {
      id: 'modal-action',
      data: {type: type, idMission: this.idMission}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['isFinish']) {
      }
    });
  }

  saveAlternative() {
    var body = {
      vatNumber: '',
      missionId: this.idMission,
      vehicleCode: this.vehicleCode,
      alternativeKey: this.vehicle.bevKey
    }
    this.livetripService.saveAlternative(body).then((res) => {
      this.livetripService.callGetLiveTrip.next(true);
      this.dialog.closeAll();
    });
  }

}
