import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LocaleService {

  readonly DEFAULT_LANG_VALUE = 'EN';
  readonly allowLanguages = ['DE','ES','FR','PT','IT', this.DEFAULT_LANG_VALUE];

  constructor(
    private translate : TranslateService,
  ) { }

  configureLanguage(lang : string){
    if(this.allowLanguages.includes(lang)){
      this.translate.setDefaultLang(lang);
    }else{
      this.translate.setDefaultLang(this.DEFAULT_LANG_VALUE);
    }
  }

}
