import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-modal-alternatives',
  templateUrl: './modal-alternatives.component.html',
  styleUrls: ['./modal-alternatives.component.scss']
})
export class ModalAlternativesComponent implements OnInit {

  dataToView: any;
  idMission: string = '';
  vehicleCode: string = '';


  constructor(public dialog: MatDialogRef<ModalAlternativesComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
    this.dataToView = [...data.bevsPull];
    this.idMission = data.idMission;
    this.vehicleCode = data.vehicleCode;
  }

  ngOnInit(): void {
    this.dataToView.shift();
  }

  ngAfterViewInit() {
  }


  closeDialog() {
    this.dialog.close()
  }

}
