import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {Constants} from './Constants';
import {LayoutComponent} from './layout/layout.component';
import {AuthGuard} from "./_services/guards/auth.guard";
import {GigyaGuard} from './_services/guards/gigya-guard';


const routes: Routes = [
  {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
  {path: 'privacy', loadChildren: () => import('./layout/privacy/privacy.module').then(mod => mod.PrivacyModule)},
  {
    path: Constants.Routing.PASSWORD_SUCCESS.path,
    loadChildren: () => import('./layout/password-changed/password-changed.module').then(mod => mod.PasswordChangedModule),
  },
  {
    path: Constants.Routing.RESET_PASSWORD.path,
    loadChildren: () => import('./layout/reset-password/reset-password.module').then(mod => mod.ResetPasswordModule),
  },
  {
    path: Constants.Routing.ACCOUNT_SUCCESS.path,
    loadChildren: () => import('./layout/account-success/account-success.module').then(mod => mod.AccountSuccessModule),
  },
  {
    path: Constants.Routing.ACCOUNT_ERROR.path,
    loadChildren: () => import('./layout/account-activation-error/account-activation-error.module').then(mod => mod.AccountActivationErrorModule),
  },
  {
    path: '', component: LayoutComponent, canActivate: [AuthGuard],
    children: [
      {
        path: Constants.Routing.PROFILE.path,
        loadChildren: () => import('./layout/profile/profile.module').then(mod => mod.ProfileModule)
      },
      {
        path: Constants.Routing.DASHBOARD.path,
        loadChildren: () => import('./layout/dashboard/dashboard.module').then(mod => mod.DashboardModule)
      },
      {
        path: Constants.Routing.HOME.path,
        loadChildren: () => import('./layout/home/home.module').then(mod => mod.HomeModule)
      },
      {
        path: Constants.Routing.PROFILE_UPDATE.path,
        loadChildren: () => import('./layout/profile-update/profile-update.module').then(mod => mod.ProfileUpdateModule)
      }
    ]
  },
  {
    path: Constants.Routing.LOGIN.path,
    canActivate: [GigyaGuard],
    loadChildren: () => import('./layout/login/login.module').then(mod => mod.LoginModule)
  },
  {
    path: Constants.Routing.REGISTRATION.path,
    canActivate: [GigyaGuard],
    loadChildren: () => import('./layout/registration/registration.module').then(mod => mod.RegistrationModule)
  },
  {
    path: Constants.Routing.COMPLETE_REGISTRATION.path,
    canActivate: [GigyaGuard],
    loadChildren: () => import('./layout/pending-registration/pending-registration.module').then(mod => mod.PendingRegistrationModule)
  },
  {path: '**', redirectTo: 'dashboard', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
