import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {AlgorithmService} from "../../../_services/algorithm.service";
import {TranslateService} from "@ngx-translate/core";
import { UserProfileService } from "src/app/_services/user-profile.service";

@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})
export class RangeSliderComponent implements OnInit, AfterViewInit {
  startRangeValues = [50, 90, 100]
  @Input() startingUrban: number = 50;
  @Input() startingExtra: number = 90;
  @Output() listMixUsage: EventEmitter<any> = new EventEmitter<any>();
  lang: string = '';
  paramForVexMed: any = {};
  isReady: boolean = false;
  speedMed: number = 45;
  // slider range
  mix = {
    urban: this.startingUrban,
    extra: this.startingExtra,
    motorway: 100 - (this.startingUrban + this.startingExtra)
  };

  rangeConfig = {};
  // {
  // start: [this.startingUrban, this.startingExtra + this.startingUrban, 100],
  // connect: [true, true, true, true],
  // range: {
  //   min: [0],
  //   max: [100]
  // },
  // margin: 0,
  // step: 1,
  // tooltips: [
  //   {
  //     to: value => `${this.translateService.instant('URBAN')}: <strong id="urban">${this.mix.urban}</strong>`
  //   },
  //   {
  //     to: value => `${this.translateService.instant('EXTRA_URBAN')}: <strong id="extra">${this.mix.extra}</strong>`
  //   },
  //   {
  //     to: value => `${this.translateService.instant('MOTORWAY')}: <strong id="motorway">${this.mix.motorway}</strong>`
  //   }
  // ]
  // };

  @ViewChild("rangeSlider") rangeSlider: ElementRef;

  constructor(private _elementRef: ElementRef, private algorithmService: AlgorithmService, private translateService: TranslateService,
    private userProfileService: UserProfileService) {
  }

  ngOnInit(): void {
    this.startRangeValues = [this.startingUrban, (this.startingExtra + this.startingUrban), 100];
    this.setConfig()
    this.getParamAlgorithm();
  }

  ngAfterViewInit() {
    const slider = this.rangeSlider["el"].nativeElement;
    // disable last handle
    const origins = slider.getElementsByClassName("noUi-origin");
    origins[origins.length - 1].setAttribute("disabled", true);

    // add classes for colorClasses
    const colorClasses = ["color-range-1", "color-range-2", "color-range-3"];
    const connects = slider.querySelectorAll(".noUi-connect");
    for (let i = 0; i < connects.length - 1; i++) {
      connects[i].classList.add(colorClasses[i]);
    }
  }

  setConfig() {
    this.mix = {
      urban: this.startingUrban,
      extra: this.startingExtra,
      motorway: 100 - (this.startingUrban + this.startingExtra)
    };
    this.rangeConfig = {
      start: [this.startingUrban, this.startingExtra + this.startingUrban, 100],
      connect: [true, true, true, true],
      range: {
        min: [0],
        max: [100]
      },
      margin: 0,
      step: 1,
      tooltips: [
        {
          to: value => `${this.translateService.instant('URBAN')}: <strong id="urban">${this.startingUrban}%</strong>`
        },
        {
          to: value => `${this.translateService.instant('EXTRA_URBAN')}: <strong id="extra">${this.startingExtra}%</strong>`
        },
        {
          to: value => `${this.translateService.instant('MOTORWAY')}: <strong id="motorway">${100 - (this.startingUrban + this.startingExtra)}%</strong>`
        }
      ]
    };
    console.log(this.rangeConfig)
    this.isReady = true;
  }

  getParamAlgorithm() {
    this.algorithmService.getParamAlgorithm().then((res) => {
      this.paramForVexMed = res;
    }).catch((err) => {
      console.log(err)
    })
  }

  onEndSlide(event) {
    this.listMixUsage.emit({
      urbanUse: (this.mix.urban / 100),
      extraurbanUse: (this.mix.extra / 100),
      highwayUse: (this.mix.motorway / 100)
    });
    this.speedMed = Math.floor(this.paramForVexMed.urbanApprovedSpeed * (this.mix.urban / 100) +
      this.paramForVexMed.extraUrbanApprovedSpeed * (this.mix.extra / 100) +
      this.paramForVexMed.highwayApprovedSpeed * (this.mix.motorway / 100));
  }

  onRangeChange($values) {
    const [urban, extraVal] = $values;
    const extra = extraVal - urban;
    let motorway = 100 - urban - extra;

    this.mix.urban = urban;
    this.mix.extra = extra;
    this.mix.motorway = motorway;

    this._elementRef.nativeElement.querySelector(`#urban`).innerHTML = urban + '%';
    this._elementRef.nativeElement.querySelector(`#extra`).innerHTML = extra + '%';
    this._elementRef.nativeElement.querySelector(
      `#motorway`
    ).innerHTML = motorway + '%';
    /* Faccio l'emit del mix usage */
  }

}
