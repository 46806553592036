import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ChartOptions, ChartType} from "chart.js";
import {BaseChartDirective, Label} from "ng2-charts";
import {BevVehicle} from "../../../_models/Algorithm";
import {Observable} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {isEmpty} from "rxjs/operators";

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html'
})
export class PieChartComponent implements OnInit, AfterViewInit {
  @Input() groups: BevVehicle = {};
  @ViewChild('myPieChart') canvas: HTMLCanvasElement;
  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;
  showMsg: boolean = false;

// Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      labels: {
        fontColor: 'white',
        fontSize: 16,
        boxWidth: 15,
        padding: 5,
      },
      align: 'end',
    },
    tooltips: {
      backgroundColor: 'rgba(10,10,10,0.75)',
    },
    animation: {
      duration: 2000,
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    },
    scales: {
      gridLines: {
        color: 'rgba(17,132,189,0.35)',
        drawTicks: false,
        drawBorder: false,
        zeroLineColor: 'rgba(17,132,189,0.35)'
      },
      ticks: {
        fontColor: 'rgba(17,132,189,0.35)'
      },
    },
    elements: {
      arc: {
        borderColor: 'rgba(255, 255, 255, 0.5)',
        borderWidth: 3
      },
    }
  };

  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  // public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(17,132,189,0.35)', 'rgba(91,111,121,0.35)', 'rgba(12,74,53,0.35)'],
    },
  ];


  constructor(private translateService: TranslateService) {
  }

  ngOnInit(): void {
    console.log(this.groups)
    this.showMsg = Object.keys(this.groups).length > 0 ? false: true;
  }

  ngAfterViewInit() {
    this.initDataForChart(this.groups).subscribe((res) => {
      if (res) {
        this.chart.update();
        console.log('Data For Line Chart ready')
      }
    })
  }

  // events
  public chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }


  initDataForChart(data): Observable<boolean> {
    return new Observable<boolean>(o => {
      var grd: any = [];
      // @ts-ignore
      var ctx = this.canvas.nativeElement.getContext('2d')
      var x = Math.floor((ctx.canvas.clientWidth/2));
      var y = Math.floor((ctx.canvas.clientHeight/2));
      var i = 0;


      var keys = Object.keys(data);
      // this.pieChartLabels = Object.keys(data);
      keys.forEach((key) => {
        this.pieChartLabels.push((this.translateService.instant(data[key].content.bevBodyTypeDescription) + '\n'+
          this.translateService.instant(data[key].content.bevWbDescription) + '/'
          + this.translateService.instant(data[key].content.bevRoofDescription)))
        this.pieChartData.push(data[key].piePercentage)
        var gradient = ctx.createRadialGradient(x,y,(y/4), x,y,y);
        gradient.addColorStop(0, this.colorArray[i]);
        gradient.addColorStop(1, this.colorArray[(i+1)]);
        grd.push(gradient)
        ++i;
      });
      this.pieChartColors[0] = {backgroundColor: grd}
      o.next(true);
    });
  }

  addSlice(): void {
    this.pieChartLabels.push(['Line 1', 'Line 2', 'Line 3']);
    this.pieChartData.push(400);
    this.pieChartColors[0].backgroundColor.push('rgba(196,79,244,0.3)');
  }

  removeSlice(): void {
    this.pieChartLabels.pop();
    this.pieChartData.pop();
    this.pieChartColors[0].backgroundColor.pop();
  }

  changeLegendPosition(): void {
    this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  }

  public colorArray = ['#3EC5FF', '#8497A8', '#DEE5EB', '#7E6D8E', '#394855', '#C4F384', '#94C3ED'];
}

