import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Constants} from 'src/app/Constants';
import {GigyaService} from '../gigya/gigya.service';

@Injectable()
export class GigyaGuard implements CanActivate {

  constructor(private router: Router, private gigya: GigyaService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log(`Gigya guard is checking login state for ${route}, e state ${state.url}`);
    return this.gigya.isUserInCookie().pipe(map(authenticated => {
      if (authenticated) {
        console.log(`User logged in. Useless to navigate to ${state.url} route. Redirect to login`);
        this.router.navigate([Constants.Routing.DASHBOARD.path]);
        // location.href = '#/dashboard'
        return false;
      }
      return true;
    }), catchError(error => of(false)));
  }

}
