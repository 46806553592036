export class Constants {

  public static FIAT_RADIX = 'fiat';
  public static JEEP_RADIX = 'jeep';

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERROR!';
  public static TITLE_WARNING = 'WARNING!';
  public static TITLE_SUCCESS = 'SUCCESS!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Routing = class {
    public static DASHBOARD = {label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard']};
    public static HOME = {label: 'home', path: 'home', routerLink: ['/home']};
    public static LOGIN = {label: 'Login', path: 'login', routerLink: ['/login']};
    public static REGISTRATION = {label: 'Registration', path: 'register', routerLink: ['/register']};
    public static COMPLETE_REGISTRATION = {label: 'Complete Registration', path: 'complete-registration', routerLink: ['/complete-registration']};
    public static PROFILE_UPDATE = {label: 'Profile Update', path: 'profile-update', routerLink: ['/profile-update']};
    public static PROFILE = {label: 'Profile', path: 'profile', routerLink: ['/profile']};
    public static POLICY = { label: 'Policy', path:"policy", routerLink: ['/policy'] };
    public static ACCOUNT_SUCCESS = { label: 'Account Success', path:"account-success", routerLink: ['/account-success'] };
    public static ACCOUNT_ERROR = { label: 'Account Error', path:"account-activation-error", routerLink: ['/account-activation-error'] };
    public static RESET_PASSWORD = { label: 'Reset Password', path:"reset-password", routerLink: ['/reset-password'] };
    public static PASSWORD_SUCCESS = { label: 'Password Success', path:"password-success", routerLink: ['/password-success'] };

  }


}
