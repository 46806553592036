import {FormComponent} from "./components/form/form.component";
import {VehicleCardComponent} from "./components/vehicle-card/vehicle-card.component";
import {RadarChartComponent} from "./components/radar-chart/radar-chart.component";
import {ScatterChartComponent} from "./components/scatter-chart/scatter-chart.component";
import {RangeSliderComponent} from "./components/range-slider/range-slider.component";
import {SideVehicleCardComponent} from "./components/side-vehicle-card/side-vehicle-card.component";
import {SpinnerComponent} from "./components/spinner/spinner.component";
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {InlineSVGModule} from "ng-inline-svg";
import {NgModule} from "@angular/core";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ChartsModule} from "ng2-charts";
import {NouisliderModule} from "ng2-nouislider";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatMenuModule} from "@angular/material/menu";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSliderModule} from "@angular/material/slider";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from "@angular/material/radio";
import {LineChartComponent} from './components/line-chart/line-chart.component';
import {PieChartComponent} from './components/pie-chart/pie-chart.component';
import {MatIconModule} from "@angular/material/icon";
import {ModalActionComponent} from './components/modal-action/modal-action.component';
import {MatDialogModule} from "@angular/material/dialog";
import {SwiperModule, SwiperConfigInterface, SWIPER_CONFIG} from "ngx-swiper-wrapper";
import {ModalTableComponent} from './components/modal-table/modal-table.component';
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {VehicleGroupViewComponent} from './components/vehicle-group-view/vehicle-group-view.component';
import {ModalAlternativesComponent} from './components/modal-alternatives/modal-alternatives.component';
import {AlgorithmChangerComponent} from './components/algorithm-changer/algorithm-changer.component';
import {MapWaypointsComponent} from './components/map-waypoints/map-waypoints.component';
import {AgmCoreModule, GoogleMapsAPIWrapper} from "@agm/core";
import {AgmDirectionModule} from "agm-direction";
import { InfoModalComponent } from './components/info-modal/info-modal.component';


// SwiperOptions from 'swiper' could also be used here instead of SwiperConfigInterface
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    FormComponent,
    VehicleCardComponent,
    RadarChartComponent,
    ScatterChartComponent,
    RangeSliderComponent,
    SideVehicleCardComponent,
    SpinnerComponent,
    LineChartComponent,
    PieChartComponent,
    ModalActionComponent,
    ModalTableComponent,
    VehicleGroupViewComponent,
    ModalAlternativesComponent,
    AlgorithmChangerComponent,
    MapWaypointsComponent,
    InfoModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule,
    ChartsModule,
    NouisliderModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatTabsModule,
    MatSliderModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonToggleModule,
    MatIconModule,
    MatTableModule,
    MatPaginatorModule,
    FormsModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    SwiperModule,
    InlineSVGModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAP9AJOcMC8Kr6SN96vC32Oa7RPcbcJjhM',
      libraries: ["places"]
    }),
    AgmDirectionModule,
  ],
  exports: [
    FormComponent,
    VehicleCardComponent,
    RadarChartComponent,
    ScatterChartComponent,
    RangeSliderComponent,
    SideVehicleCardComponent,
    LineChartComponent,
    VehicleGroupViewComponent,
    PieChartComponent,
    SpinnerComponent,
    MatProgressSpinnerModule,
    ChartsModule,
    MatDialogModule,
    NouisliderModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatMenuModule,
    MatTabsModule,
    MatIconModule,
    MatSliderModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatTooltipModule,
    SwiperModule,
    ModalTableComponent,
    AlgorithmChangerComponent,
    MapWaypointsComponent,
    InfoModalComponent
  ],
  bootstrap: [SpinnerComponent],
  providers: [GoogleMapsAPIWrapper,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]
})
export class SharedModule {
}
