import {Component, Inject, OnInit} from '@angular/core';
import {SessionStoragerService} from "../../../_services/storager/session-storager.service";
import {TranslateService} from "@ngx-translate/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html'
})
export class InfoModalComponent implements OnInit {
  noOpenModalInfo: boolean = false;

  ngOnInit(): void {
  }

  constructor(public dialog: MatDialogRef<InfoModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private translate: TranslateService,
              private storager: SessionStoragerService) {
  }

  modalClosed() {
    this.dialog.close()
    // this.simulationService.modalInfoEventClose.next(this.noOpenModalInfo);
    this.storager.setElement('infoModal', this.noOpenModalInfo);
  }


  translateInfo() {
    var allStringInfo = '';
    var searchRegExp = /\./g;
    this.translate.get('informationTravelDesc').subscribe((res) => {
      allStringInfo = res;
    })
    var replacedString = allStringInfo.replace(searchRegExp, '.<br><br>')
    return replacedString
  }


}
