<div class="sideMainCard position-relative" [ngStyle]="{'border-top-color':  vehicle.colorMainCard}">
  <div class="container topCard">
    <div class="row">
      <div class="col-12 col-md-9">
        <div class="messagePageTitle mt-2 vehicleName">
          <span>{{ vehicle.bevBodyTypeDescription | translate }} {{vehicle.bevWbDescription | translate}}
            / {{vehicle.bevRoofDescription | translate}}
            <span *ngIf="utilityService.getTypeOfVehicle(vehicle.bevBodyTypeDescription) == 'PEOPLE'">
              {{'SEATS' | translate}}
            </span>
          </span>
          <br>
          <span *ngIf="vehicle.selected" class="boldWhite my-2 selected_vehicle">{{'REQUIRED_SIZE' | translate}}</span>
        </div>
      </div>
      <div class="col-12 col-md-3 p-0">
        <div class="img-fluid vehicleImage">
          <img [src]="utilityService.imageVehicle(vehicle.bevBodyTypeDescription)" alt=""/>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <p class="color-white">
              {{'BATTERY' | translate}} <span class="boldWhite">{{vehicle.battery | translate}}</span> |
              {{'SPEED_RECHARGE' | translate}} <span
              class="boldWhite">{{vehicle.onboardCharging | translate }}
              - {{vehicle.fullChargingTime}}</span> {{'TO_COMPLETE_RECHARGE' | translate}}
              <br/>
              {{'RECOMMENDED_SPEED' | translate}} <span class="boldWhite">{{vehicle.recommendedSpeed}} km/h</span> |
              {{'LOAD_COMPARTMENT' | translate}} <span class="boldWhite">{{vehicle.bevLoadCompartment}}
              m3 - {{vehicle.bevPayload}}
              kg</span> {{'max' | translate}}
            </p>
            <hr/>

            <div
              class="vehicleSavings d-flex justify-content-start align-items-center"
            >
              <div
                class="icon savings-icon mr-2"
                aria-label="savings-icon"
                [inlineSVG]="'/assets/icons/savings.svg'"
              ></div>
              <span class="color-white mr-2">{{'SAVING_FUEL_COST' | translate}}</span>
              <span
                class="color-white font-weight-bold">{{vehicle.saving}} {{'PLACEHOLDER_YEAR_COST' | translate}} {{'PER_VEHICLE'| translate}}</span>
            </div>

            <hr/>

            <div class="row color-white">
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-1">
                    <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_SUFFICIENT'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/check.svg'"
                    ></div>
                    <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_CRITICAL'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/wave.svg'"
                    ></div>
                    <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_INSUFFICIENT'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/ko.svg'"
                    ></div>
                  </div>
                  <div class="col-10">
                    <span class="missionTitle">{{'RESIDUAL_AUTONOMY' | translate}}</span>
                    <span class="missionDetail boldWhite">
                      {{vehicle.residualAutonomy > 0 ? vehicle.residualAutonomy : 0}} km / {{vehicle.autonomy}}
                      km</span>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-1">
                    <div *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_SUFFICIENT'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/check.svg'"
                    ></div>
                    <div
                      *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_INSUFFICIENT' && (vehicle.fitToMissionDesc == 'MISSION_CRITICAL' || vehicle.fitToMissionDesc == 'MISSION_COMPLETED')"
                      class="icon check-icon"
                      aria-label="check-icon"
                      [inlineSVG]="'/assets/icons/wave.svg'"
                    ></div>
                    <div
                      *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_INSUFFICIENT' && vehicle.fitToMissionDesc == 'MISSION_FAILED'"
                      class="icon check-icon"
                      aria-label="check-icon"
                      [inlineSVG]="'/assets/icons/ko.svg'"
                    ></div>
                  </div>
                  <div class="col-10">
                    <span class="missionTitle">{{'LONG_RECHARGE' | translate}}: </span>
                    <span class="missionDetail boldWhite">{{vehicle.lcsDesc| translate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-2">
                <div class="row">
                  <div class="col-1">
                    <div *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_UNNECESSARY'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/check.svg'"
                    ></div>
                    <div
                      *ngIf="vehicle.scsDesc == 'FIXED_SHORT_CHARGING_NECESSARY' || vehicle.scsDesc == 'FIXED_SHORT_CHARGING_RECOMMENDED'"
                      class="icon check-icon"
                      aria-label="check-icon"
                      [inlineSVG]="'/assets/icons/wave.svg'"
                    ></div>
                    <div *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_NECESSARY'"
                         class="icon check-icon"
                         aria-label="check-icon"
                         [inlineSVG]="'/assets/icons/ko.svg'"
                    ></div>
                  </div>
                  <div class="col-10">
                    <span class="missionTitle">{{'SHORT_RECHARGE' | translate}}:</span>
                    <span class="missionDetail boldWhite">
                      {{vehicle.scsDesc| translate}} <span
                      *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_NECESSARY'">{{vehicle.scsDuration}}</span></span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container botCard">
    <div>
      <span class="missionState"
            [ngClass]="{'critical': vehicle.fitToMissionDesc == 'MISSION_CRITICAL',
               'failed': 'MISSION_FAILED' == vehicle.fitToMissionDesc,
               'success': vehicle.fitToMissionDesc == 'MISSION_COMPLETED'}"
      >{{'MISSION_STATUS' | translate}} <span class="font-weight-bold">{{vehicle.fitToMissionDesc | translate}}</span>
      </span> <br/>
    </div>
    <hr/>
    <div class="mt-3 color-white">
      <p class="boldWhite">{{'CONFIGURE_OPTIONAL' | translate}}:</p>
      <div class="row">
        <div class="col-12">
          <p>{{'UPGRADE_BATTERY' | translate}}</p>
        </div>
        <div class="col-12">
          <div class="checkboxContainer boldWhite">
            <mat-radio-group class="checkboxContainer boldWhite" (change)="changeVehicleVar()"
                             [(ngModel)]="vehicle.checkedBattery">
              <mat-radio-button [value]="47">{{'BATTERY_47_DESC' | translate}}</mat-radio-button>
              &nbsp;
              <mat-radio-button [value]="79">{{'BATTERY_79_DESC' | translate}}</mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            {{'INCREASE_POWER' | translate}}
          </p>
        </div>
        <div class="col-12">
          <div class="checkboxContainer boldWhite">

            <mat-radio-group class="checkboxContainer boldWhite" (change)="changeVehicleVar()"
                             [(ngModel)]="vehicle.checkedCharger">
              <div class="row">
                <div class="col-6">
                  <mat-radio-button [disabled]="vehicle.checkedBattery == 79"
                                    [value]="6.6">{{'ONBOARD_CHARGING_6_6_FULL_DESC' | translate}}</mat-radio-button>
                </div>

                <div class="col-6">
                  <mat-radio-button [value]="11">{{'ONBOARD_CHARGING_11_FULL_DESC' | translate}}</mat-radio-button>
                </div>

                <div class="col-6">
                  <mat-radio-button class="disabled" disabled [value]="22">{{'ONBOARD_CHARGING_22_FULL_DESC' | translate}}</mat-radio-button>
                </div>

                <div class="col-6">
                  <mat-radio-button [value]="50">{{'ONBOARD_CHARGING_50_FULL_DESC' | translate}}</mat-radio-button>
                </div>
              </div>
            </mat-radio-group>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="select_mission_card" (click)="saveSimulation()"><span
    class="boldPrimary">{{'SELECT_AND_SAVE' | translate}}</span>&nbsp;&nbsp;<div
    [inlineSVG]="'/assets/icons/star.svg'"></div>
  </div>
</div>
