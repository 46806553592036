import { Component, OnInit } from '@angular/core';
interface Prefix {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  prefixes: Prefix[] = [
    {value: '+39', viewValue: '+39'},
    {value: '+38', viewValue: '+38'},
    {value: '+1', viewValue: '+1'}
  ];

}
