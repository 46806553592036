import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ChartDataSets, ChartType, RadialChartOptions} from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import {AlgorithmResponse, BevVehicle} from "../../../_models/Algorithm";
import {VehicleService} from "../../../_services/vehicle.service";
import {ChartsService} from "../../../_services/charts.service";
import {UtilityService} from "../../../_services/utility.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'radar-chart',
  templateUrl: './radar-chart.component.html',
  // styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent implements OnInit {

  @Input() vhecType: string = 'VAN';
  @Input() selected: BevVehicle;
  @Input() fleetModels: AlgorithmResponse;

  @ViewChild(BaseChartDirective, {static: true}) chart: BaseChartDirective;

  public radarChartOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    title: {
      fontColor: 'white'
    },
    scale: {
      gridLines: {
        color: 'rgba(255,255,255,1)',
        circular: true,
      },
      pointLabels: {
        fontColor: 'rgba(255,255,255,1)',
        fontSize: 15
      },
      ticks: {
        fontColor: 'rgba(255,255,255,1)'
      }
    },
  };
  public radarChartType: ChartType = 'radar';
  public labels: Label[] = ["PAYLOAD", 'AUTONOMY', 'AVERAGE_SPEED', 'LONG_RECHARGE_TIME', 'SHORT_RECHARGE_TIME', 'SAVING_FUEL_COST'];
  public radarChartLabels: Label[] = [];
  public radarChartData: ChartDataSets[] = [];
  public colors: Array<any> = [];
  public colorObjCopy: Array<any> = [];

  constructor(private vehicleService: VehicleService, private chartsService: ChartsService,
              private utilityService: UtilityService, private translateService: TranslateService) {

    this.vehicleService._vehicleSelected.subscribe((item) => {
      this.selected = item;
      this.orderChatData(item);
    });

    this.chartsService._updateChart.subscribe((val) => {
      var self = this;
      if (val) {
        setTimeout(function () {
          self.setStaticDetails();
        }, 250)
      }
    });
  }

  ngOnInit(): void {
    this.setStaticDetails();
  }

  setStaticDetails() {
    this.radarChartData = [];
    this.colors = [];
    this.radarChartLabels = [];

    this.colors = [
      { // Min
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        pointHoverBorderColor: 'transparent',
      },
      { // Ref Medium
        backgroundColor: 'rgba(62, 197, 255, 0.1)',
        borderColor: 'green',
        borderDash: [10, 10],
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(77,83,96,1)',
      },
    ];
    if (this.utilityService.getTypeOfVehicle(this.selected.bevBodyTypeDescription) == 'PEOPLE') {
      var i = this.labels.indexOf('PAYLOAD');
      this.labels[i] = 'NSEATS';
    }
    this.labels.forEach((lab) => {
      this.radarChartLabels.push((this.translateService.instant(lab)))
    })

    this.radarChartData = [
      {data: [50, 50, 50, 50, 50, 50], label: 'Standard'},
      {data: [100, 100, 100, 100, 100, 100], label: 'Standard'},
    ];
    this.generateScatterDetails();
  }

  generateScatterDetails() {
    const self = this;
    this.fleetModels.bevsPull.forEach((vec) => {
      var dataSets: ChartDataSets = {
        data: [vec.payloadIndex, vec.taIndex, vec.avgCommSpeedIndex, vec.lcsIndex, vec.scsIndex, vec.savingIndex],
        label: vec.pallogramId,
      };
      var color = {
        backgroundColor: this.utilityService.hexToRgbA(vec.colorMainCard, 10),
        borderColor: vec.colorMainCard,
        pointBackgroundColor: vec.colorMainCard,
        pointBorderColor: vec.colorMainCard,
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: vec.colorMainCard,
      }
      self.radarChartData.push(dataSets);
      self.colors.push(color);
    });
    this.orderChatData(this.selected);
  }

  // events
  public chartClicked({event, active}: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({event, active}: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  orderChatData(item: BevVehicle) {
    var indexOfCharts = this.colors.map(function (e) {
      return e.pointHoverBorderColor
    }).indexOf(item.colorMainCard);
    this.colors = this.array_move(this.colors, indexOfCharts, 2);
    this.radarChartData = this.array_move(this.radarChartData, indexOfCharts, 2);
    for (let i = 2; this.colors.length > i; i++) {
      var rgba = this.utilityService.hexToRgbA(this.colors[i].pointHoverBorderColor, 25);
      if(i == 2){
        rgba = this.colors[i].pointHoverBorderColor
      }
      this.colors[i].borderColor = rgba;
      this.colors[i].pointBackgroundColor = rgba;
      this.colors[i].pointBorderColor = rgba;
      this.colors[i].pointHoverBackgroundColor = rgba;
    }
    this.chart.update();
  }

  private array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    console.log(arr)
    return arr; // for testing
  };


  public tooltipLabel = {
    "PAYLOAD": 'Capacità di carico della versione',
    "NSEATS": 'Numero di posti a sedere',
    'AUTONOMY': 'Autonomia totale del veicolo in base allo stile d\'uso del cliente, considerando sia le soste lunghe che le soste brevi ',
    'AVERAGE_SPEED': 'Velocità media rilevata confrontata con quella derivata dalle rispettive tipologie d\'uso',
    'LONG_RECHARGE_TIME': 'tempo dichiarato dal cliente per la ricarica lunga  confrontato con i tempi necessari per caricare completamente la batteria',
    'SHORT_RECHARGE_TIME': 'tempo dichiarato dal cliente per la ricarica breve  rapportato con i tempi di ricarica necessari per completare il viaggio',
    'SAVING_FUEL_COST': 'Risparmi sul carburante stimati confrontando la versione elettrica con quella termica'
  }
}
