export class Algorithm {

  vatNumber?: string;
  modelCode?: number;
  wb?: string;
  roof?: string;
  gvw?: any;
  bodyType?: string;
  body?: string;
  weekWorkingDays?: number;
  yearWorkingWeeks?: number;
  fuelDieselCost?: number;
  fuelBevCost?: number;
  temperature?: number;
  payload?: number;
  avgPayload?: number;
  km?: number;
  shortChargingSlot?: number;
  longChargingSlot?: number;
  urbanUse?: number
  extraurbanUse?: number;
  highwayUse?: number;
  vehiclesNumber?: number;
  configName?: string = '';
}

export class AlgorithmImprovement extends Algorithm {

  heatId?: string;
  bevKey?: string;
  battery?: number;
  onboardCharging?: number;
  checkedBattery?: number;
  checkedCharger?: number;

}

export class AlgorithmResponse {

  heatId?: string;
  heatKey?: string;
  bevsPull?: BevVehicle[];

}

export class BevVehicle {

  bevKey?: string;
  battery?: string;
  onboardCharging?: string;
  bevId?: string;
  bevWbDescription?: string;
  bevRoofDescription?: string;
  bevBodyTypeDescription?: string;
  bevLoadCompartment?: number;
  bevPayload?: number;
  selected?: boolean;
  fullChargingTime?: string;
  recommendedSpeed?: number;
  residualAutonomy?: number;
  autonomy?: number;
  autonomyDesc?: string;
  totalAutonomy?: number;
  saving?: number;
  payloadIndex?: number;
  taIndex?: number;
  avgCommSpeedIndex?: number;
  lcsIndex?: number;
  scsIndex?: number;
  savingIndex?: number;
  fitToMissionDesc?: string;
  pallogramId?: string;
  lcsDesc?: string;
  scsDesc?: string;
  scsDuration?: number;
  performanceIndex?: number;
  colorMainCard?: string;
  checkedBattery?: number;
  checkedCharger?: number;
  panelIsOpen?: boolean;

}


export enum CheckedBattery {
  BATTERY_47_DESC = 47,
  BATTERY_79_DESC = 79
}

export enum CheckedCharger {
  ONBOARD_CHARGING_6_6_FULL_DESC = 6.6,
  ONBOARD_CHARGING_11_FULL_DESC = 79,
  ONBOARD_CHARGING_22_FULL_DESC = 22,
  ONBOARD_CHARGING_50_FULL_DESC = 50,
}


export interface BevVehicleImprovement extends BevVehicle {

  heatId?: string;
  heatKey?: string;

}

