import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";

export interface TripSingleElement {
  createdOn: string;
  distance: number;
  averageSpeed: number;
  durationStr: string;
  temperature: string;

}

@Component({
  selector: 'app-modal-table',
  templateUrl: './modal-table.component.html',
  styleUrls: ['./modal-table.component.scss']
})
export class ModalTableComponent implements OnInit {
  displayedColumns: string[] = ['TRIP_DATE', 'TRIP_TIME', 'TEMPERATURE', 'AVERAGE_SPEED', 'KM_ROUTES'];
  dataSource = [];
  actualPage: number = 1;
  lastPage: number = 0;
  itemToViewSize: number = 10;
  dataToView: any;
  id: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialog: MatDialogRef<ModalTableComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private translateService: TranslateService) {
    this.dataSource = data.tableData;
    this.id = data.id;

    for (let i = 0; i < this.displayedColumns.length; i++) {
      this.displayedColumns[i] = this.translateService.instant(this.displayedColumns[i]);
    }
    // var lastNum = 10 - parseInt(data.tableData.length.toString().split('').pop())
    // for (let i of newArray(lastNum)){
    //   this.dataSource.push(emptytripSingle);
    // }
  }

  ngOnInit(): void {
    this.dataToView = this.dataSource.slice(0, this.itemToViewSize);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Will evaluate to true if the variable is divisible by 2
      var a = this.dataSource.length / this.itemToViewSize;
      if ((a % 1) != 0) {
        a += 1
      }
      var b = Math.floor(a);
      this.lastPage = b;
    }, 150);
  }

  prevPage() {
    this.actualPage -= 1;
    var startSlice = (this.actualPage * this.itemToViewSize) - this.itemToViewSize;
    var endSlice = this.itemToViewSize + startSlice;
    if (this.actualPage == 1) {
      startSlice = 0;
      endSlice = this.itemToViewSize;
    }
    this.dataToView = this.dataSource.slice(startSlice, endSlice);
  }

  nextPage() {
    var startSlice = this.actualPage * this.itemToViewSize;
    this.dataToView = this.dataSource.slice(startSlice, (startSlice + this.itemToViewSize));
    this.actualPage += 1;
  }

  closeDialog() {
    this.dialog.close()
  }
}
