import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {AlgorithmResponse, BevVehicle} from "../_models/Algorithm";
import {map} from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";


@Injectable({
  providedIn: 'root'
})
export class AlgorithmService {

  constructor(private http: HttpClient, private translateService: TranslateService) {
  }

  getParamAlgorithm(): Promise<any> {
    /* Get all the parameters used in the algorithm */
    return this.http.get(`${environment.apiUrl}/configs/algorithm`).toPromise();
  }

  getConfigPayLoadAlgorithm(algorithm): Promise<any> {
    return this.http.post(`${environment.apiUrl}/configs/algorithm`, algorithm).toPromise();
  }


  getVehiclesSpec(): Promise<any> {
    /* Get all the available combinations for Ducato vehicles, alphabetically ordered */
    return this.http.get(`${environment.apiUrl}/configs/vehicles-combinations`).toPromise();
  }

  getElectricModelsFleet(body): Promise<AlgorithmResponse> {
    return this.http.post<AlgorithmResponse>(`${environment.apiUrl}/algorithm/pull`, body)
      .pipe(map((res) => {
        for (let bev of res.bevsPull) {
          if (bev.selected) {
            var textToReplace = this.translateService.instant('REQUIRED_SIZE');
            bev.pallogramId = bev.pallogramId.replace('REQUIRED_SIZE', textToReplace);
            break;
          }
        }
        return res
      }))
      .toPromise();
  }

  getElectricModelsSingle(algorithm): Promise<BevVehicle> {

    return this.http.post<BevVehicle>(`${environment.apiUrl}/algorithm/single`, algorithm).toPromise();
  }


}
