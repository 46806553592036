export class LinksLang {
  dealer_locator: string = '';
  discover_vehicles: string = '';
  eco_incentives: string = '';
  recharge_locator: string = '';
  terms_conditions: string = '';
  constructor() {
  }
}

export class User extends LinksLang {

  LOGINNAME: string;
  CODMARKET: string;
  LANGUAGE: string;
  COUNTRY: string;
  FIRSTNAME: string;
  LASTNAME: string;
  EMAIL: string;
  BRANDS: Map<string, string>;
  VAT: string;
  UID: string;
  OLD_LANG: string;

  constructor() {
    super();
  }
}

export enum Country {
  IT, DE, FR, ES,
  GB, BE, NL, AT,
  CZ, PL, HU, SK,
  CH, DK, SE, NO
}

export enum Language {
  DE, FR, EN, ES, IT, PT
}
