<div class="mainCard dashboardCard viewGroupCard h-100 p-4 d-flex align-content-center"
     [ngStyle]="{'background-color': background ? background: 'inherit'}">
  <!--  <div class="container">-->
  <div *ngIf="showSave" class="select_mission_card" (click)="saveAlternative()"><span
    class="boldPrimary">{{'SELECT_AND_SAVE' | translate}}</span>&nbsp;&nbsp;<div
    [inlineSVG]="'/assets/icons/star.svg'"></div>
  </div>
  <div class="row">
    <div class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
      <div class="img-fluid" [ngClass]="{'singleViewImg': cardType == 'singleView'}">
        <img class="w-100" [src]="utilityService.imageVehicle(vehicle.bevBodyTypeDescription)" alt=""/>
      </div>
      <div *ngIf="cardType == 'singleView'" style="height: 5.25em"></div>
      <div *ngIf="cardType != 'groupView'" class="vehicleSavings justify-content-start align-items-center">
        <div class="my-2">
            <span [ngClass]="{'critical': vehicle?.compatibility < 50,
               'failed': vehicle?.compatibility >= 50 && vehicle?.compatibility < 70, 'success': vehicle?.compatibility >= 70}"
                  class="missionState">{{'COMPATIBILITY' | translate}}:
              <span class="font-weight-bold">{{vehicle?.compatibility | translate}}{{'%'}}</span></span><br/>
        </div>
        <div class="vehicleSavings justify-content-start align-items-center">
          <span class="color-white">{{'SAVING_FUEL_COST' | translate}}</span>
          <br>
          <span class="boldWhite">{{vehicle?.saving}} {{'PLACEHOLDER_YEAR_COST' | translate}}</span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-9 d-flex flex-column justify-content-center">
      <div class="messagePageTitle mt-2 vehicleName">
          <span>{{ vehicle?.bevBodyTypeDescription | translate }} {{vehicle?.bevWbDescription | translate}}
            / {{vehicle?.bevRoofDescription | translate}}</span>
      </div>

      <div class="row opacity-40">
        <div class="col-12 mt-2">
          <p class="color-white vehicleInfo" style="line-height: 22px;">
            {{'BATTERY' | translate}} <span class="boldWhite">
              {{vehicle?.battery | translate}}</span>
            | {{'SPEED_RECHARGE' | translate}}
            <span
              class="boldWhite">{{vehicle?.onboardCharging | translate }}
              - {{vehicle?.fullChargingTime}}</span> {{'TO_COMPLETE_RECHARGE' | translate}}
            <br/>

            <span *ngIf="cardType == 'singleView'">{{'RECOMMENDED_SPEED' | translate}} <span class="boldWhite">{{vehicle?.recommendedSpeed}} km/h</span> </span>
            | {{'LOAD_COMPARTMENT' | translate}}
            <span class="boldWhite">{{vehicle?.bevLoadCompartment}} m3 - {{vehicle?.bevPayload}}
              kg</span> {{'max' | translate}}
          </p>
          <div class="my-3" *ngIf="cardType === 'mainCard'">
              <span [ngClass]="{'critical': vehicle?.compatibility < 50,
               'failed': vehicle?.compatibility >= 50 && vehicle?.compatibility < 70, 'success': vehicle?.compatibility >= 70}"
                    class="missionState">{{'COMPATIBILITY' | translate}}:
                <span class="font-weight-bold">{{vehicle?.compatibility | translate}} {{'%'}}</span>
              </span><br/>
          </div>
        </div>
      </div>
      <div *ngIf="cardType != 'groupView'" class="row justify-content-between color-white">
        <div class="col-12 col-md-4">
          <span class="missionTitle">{{'RESIDUAL_AUTONOMY' | translate}}</span> <br/>
          <div class="row row justify-content-center align-items-center my-2">
            <div class="col-2">
              <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_SUFFICIENT'"
                   class="icon check-icon"
                   aria-label="check-icon"
                   [inlineSVG]="'/assets/icons/check.svg'"
              ></div>
              <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_CRITICAL'"
                   class="icon check-icon"
                   aria-label="check-icon"
                   [inlineSVG]="'/assets/icons/wave.svg'"
              ></div>
              <div *ngIf="vehicle.autonomyDesc == 'AUTONOMY_INSUFFICIENT'"
                   class="icon check-icon"
                   aria-label="check-icon"
                   [inlineSVG]="'/assets/icons/ko.svg'"
              ></div>
            </div>
            <div class="col-10">
                    <span
                      class="missionDetail boldWhite">{{vehicle.residualAutonomy > 0 ? vehicle.residualAutonomy : 0}} km / {{vehicle.autonomy}}
                      km</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <span class="missionTitle">{{'LONG_RECHARGE' | translate}}</span><br/>
          <div class="row justify-content-center align-items-center my-2">
            <div class="col-2">
              <div *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_SUFFICIENT'"
                   class="icon check-icon"
                   aria-label="check-icon"
                   [inlineSVG]="'/assets/icons/check.svg'"
              ></div>
              <div
                *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_INSUFFICIENT' && (vehicle.fitToMissionDesc == 'MISSION_CRITICAL' || vehicle.fitToMissionDesc == 'MISSION_COMPLETED')"
                class="icon check-icon"
                aria-label="check-icon"
                [inlineSVG]="'/assets/icons/wave.svg'"
              ></div>
              <div
                *ngIf="vehicle.lcsDesc == 'LONG_CHARGING_INSUFFICIENT' && vehicle.fitToMissionDesc == 'MISSION_FAILED'"
                class="icon check-icon"
                aria-label="check-icon"
                [inlineSVG]="'/assets/icons/ko.svg'"
              ></div>
            </div>
            <div class="col-10">
              <span class="missionDetail boldWhite">{{vehicle.lcsDesc| translate}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <span class="missionTitle">{{'SHORT_RECHARGE' | translate}}</span> <br/>
          <div class="row justify-content-center align-items-center my-2">
            <div class="col-2">
              <div *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_UNNECESSARY'"
                   class="icon check-icon"
                   aria-label="check-icon"
                   [inlineSVG]="'/assets/icons/check.svg'"
              ></div>
              <div
                *ngIf="vehicle.scsDesc == 'FIXED_SHORT_CHARGING_NECESSARY' || vehicle.scsDesc == 'FIXED_SHORT_CHARGING_RECOMMENDED'"
                class="icon check-icon"
                aria-label="check-icon"
                [inlineSVG]="'/assets/icons/wave.svg'"
              ></div>
              <div *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_NECESSARY'"
                   class="icon check-icon"
                   aria-label="check-icon"
                   [inlineSVG]="'/assets/icons/ko.svg'"
              ></div>
            </div>
            <div class="col-10">
                    <span class="missionDetail boldWhite">{{vehicle.scsDesc | translate}} <span
                      *ngIf="vehicle.scsDesc == 'SHORT_CHARGING_NECESSARY'">{{vehicle.scsDuration}}</span></span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="cardType == 'singleView'" class="row text-center mt-3">
        <div class="col-12">
          <button (click)="showAlternatives()" class="boldPrimary">
            <h4>{{'VIEW_ALTERNATIVES' | translate | uppercase}}</h4></button>
        </div>
      </div>
    </div>
  </div>
</div>
<!--</div>-->
