import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscriber} from 'rxjs';
import {User} from '../_models/User';
import {LocaleService} from './locale.service';
import {AccountInfo} from "./gigya/screenset/account-info";
import {CookieStoragerService} from "./storager/cookie-storager.service";
import {filter} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private _user = new BehaviorSubject<User>(null);
  public user = this._user.asObservable().pipe(filter(value => value != null));
  private _links = new BehaviorSubject<User>(null);
  public links = this._links.asObservable().pipe(filter(value => value != null));

  constructor(
    private localeSrv: LocaleService,
    private storager: CookieStoragerService,
  ) {
    this.user.subscribe(user => {
        this.localeSrv.configureLanguage(user.LANGUAGE);
      }
    );
  }

  next(user: User) {
    this._user.next(user);
  }

  nextLinks(user: User) {
    this._user.next(user);
  }

  getValue(): User {
    return this._user.getValue();
  }

  getLinksValue(): User {
    return this._user.getValue();
  }

  getVatNumber() {
    return this._user.getValue().VAT;
  }

  setUserValue(res: AccountInfo, expirationDate?) {
    var exp = new Date();
    exp.setDate(exp.getDate() + 1)
    if (res) {
      var keys = Object.keys(res.data.VATRegex);
      let vatN = res.data.VATRegex[keys[0]]
      if(!res.data.VATRegex[keys[0]].includes(keys[0])){
        vatN = keys[0] + res.data.VATRegex[keys[0]]
      }

      let user: User = new User();
      user.FIRSTNAME = res.data?.firstNameRegex ? res.data.firstNameRegex : 'NONAME';
      user.LASTNAME = res.data?.lastNameRegex ? res.data.lastNameRegex : 'NOLASTNAME';
      user.EMAIL = res.profile?.email ? res.profile.email : 'google@gmail.com';
      user.VAT = vatN;
      user.LANGUAGE = res.profile?.locale.toUpperCase() ? res.profile.locale.toUpperCase() : 'EN';
      user.COUNTRY = res.profile?.country.toUpperCase() ? res.profile.country.toUpperCase() : 'GB';
      user.CODMARKET = res.data?.disclaimerCodeGoe ? res.data.disclaimerCodeGoe : '00111';
      user.BRANDS = res.data?.applications ? res.data.applications : null;
      exp = expirationDate ? expirationDate : exp;
      this.storager.setElement('G-STE-USER', user, false, exp);

      this.next(user);
    }
  }

  setUserObs(res: AccountInfo, obs: Subscriber<boolean>) {

    var keys = Object.keys(res.data.VATRegex);
    let vatN = res.data.VATRegex[keys[0]]
    if(!res.data.VATRegex[keys[0]].includes(keys[0])){
      vatN = keys[0] + res.data.VATRegex[keys[0]]
    }
    let user: User = new User();
    user.FIRSTNAME = res.data?.firstNameRegex ? res.data.firstNameRegex : 'NONAME';
    user.LASTNAME = res.data?.lastNameRegex ? res.data.lastNameRegex : 'NOLASTNAME';
    user.EMAIL = res.profile?.email ? res.profile.email : 'google@gmail.com';
    user.VAT = vatN;
    user.LANGUAGE = res.profile?.locale.toUpperCase() ? res.profile.locale.toUpperCase() : 'EN';
    user.COUNTRY = res.profile?.country.toUpperCase() ? res.profile.country.toUpperCase() : 'GB';
    user.CODMARKET = res.data?.disclaimerCodeGoe ? res.data.disclaimerCodeGoe : '00111';
    user.BRANDS = res.data?.applications ? res.data.applications : null;
    this.next(user);

    obs.next(true)
  }


}
