import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieStoragerService} from './storager/cookie-storager.service';
import {User} from '../_models/User';
import {UserProfileService} from './user-profile.service';
import {AccountInfo} from "./gigya/screenset/account-info";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private jwtHelper: JwtHelperService;
  readonly USER_KEY: string = 'user';

  constructor(
    private router: Router,
    private http: HttpClient,
    private storager: CookieStoragerService,
    private userService: UserProfileService,
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  isAuthenticated(): boolean {
    const rawToken = this.getToken();
    this.jwtHelper.getTokenExpirationDate(rawToken);
    if (isNullOrUndefined(rawToken)) {
      return false;
    }

    if (this.jwtHelper.isTokenExpired(rawToken)) {
      this.storager.removeElement(this.USER_KEY);
      this.storager.removeElement('G-STE-USER');
      return false;
    }

    //in this moment the userprofile service will be initialized by the cookie information
    if (this.userService.getValue() == null && isNullOrUndefined(rawToken)) {
      let user: User = <User>this.storager.getElement('G-STE-USER');
      this.userService.next(user);
    }

    return true;
  }

  goToLogin(): Observable<boolean> {
    this.storager.removeElement(this.USER_KEY);
    this.storager.removeElement('G-STE-USER');
    this.router.navigate(['/login']);
    return new Observable<boolean>(o => o.next(false));
  }

  authenticate(gigyaJWT: string, accountInfo: AccountInfo): Observable<boolean> {
    if(gigyaJWT){
      var keys = Object.keys(accountInfo.data.VATRegex);
      let vatN = keys[0] + accountInfo.data.VATRegex[keys[0]]
      return this.http.post<any>(`${environment.apiUrl}/auth/`, {"GigyaJWTToken": gigyaJWT, VatNumber: vatN}
      ).pipe(
        map(
          res => {
            const jwt = res.JWTToken;
            const expirationDate = this.jwtHelper.getTokenExpirationDate(jwt);
            this.storager.setElement(this.USER_KEY, res, false, expirationDate);
            this.userService.setUserValue(accountInfo, expirationDate);
            return true;
          }
        ),
        catchError(err => {
          return this.goToLogin();
        }),
      );
    }else{
      return this.goToLogin();
    }
  }

  getToken(): string {
    const usr = this.storager.getElement(this.USER_KEY);
    return !isNullOrUndefined(usr) ? usr.JWTToken : null;
    // return 'edtv565n8cf7847c657c637c7';
  }
}
