import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {UserProfileService} from "./user-profile.service";
import {LinksLang, User} from "../_models/User";


@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  user: User;
  links: LinksLang;

  constructor(private http: HttpClient, private userProfileService: UserProfileService) {
    this.user = this.userProfileService.getValue();
  }

  getDynamicLinks() {
    /* Get all the dynamic links used in the algorithm */
    this.http.get<LinksLang>(`${environment.apiUrl}/links/${this.user.COUNTRY}/${this.user.LANGUAGE.toLowerCase()}`)
      .subscribe((res) => {
        this.user.dealer_locator = res.dealer_locator;
        this.user.discover_vehicles = res.discover_vehicles;
        this.user.eco_incentives = res.eco_incentives;
        this.user.recharge_locator = res.recharge_locator;
        this.user.terms_conditions = res.terms_conditions;
        this.userProfileService.next(this.user)
      });
  }


  public hexToRgbA(hex, opacity) {
    var c;
    var o = opacity / 100;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + o + ')';
    }
    // throw new Error('Bad Hex');
  }

  imageVehicle(typeDesc: string) {
    var body = 'van'
    if (typeDesc.includes('PEOPLE')) {
      body = 'people'
    } else if (typeDesc.includes('CAB')) {
      body = 'cab'
    }
    var a = './assets/images/' + body + '.png';
    return a
  }

  getTypeOfVehicle(typeDesc: string) {
    var body = 'VAN';
    if (typeDesc.includes('PEOPLE')) {
      body = 'PEOPLE'
    } else if (typeDesc.includes('CAB')) {
      body = 'CAB'
    }
    return body
  }

  changeOpacityRGBA(color: string, opacity: number) {
    let colorSplit = color.split(',')
    let colorLength = colorSplit.length - 1;
    let colorOpacity = colorSplit[colorLength].replace(')', '');
    colorOpacity = opacity.toString()
    let rgba = colorSplit[0] + ',' + colorSplit[1] + ',' + colorSplit[2] + ',' + colorOpacity + ')'
    return rgba;
  }

  /**
   * Return formatted date 'YearMonthDay' like '20210120'
   *@param date
   * @return string
   * */
  formatDate(date: Date): string {
    let day = date.getDate();
    let month = (date.getMonth() + 1);
    let year = date.getFullYear();
    var dayss = day > 9 ? day : '0' + day;
    var months = month > 9 ? month : '0' + month;
    let dateFormatted = year + '' + months + '' + dayss;
    return dateFormatted;
  }
}


