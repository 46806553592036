import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SimulatorService} from "../../../_services/simulator.service";

@Component({
  selector: 'app-modal-action',
  templateUrl: './modal-action.component.html',
  styleUrls: ['./modal-action.component.scss']
})
export class ModalActionComponent implements OnInit {

  message: string = '';

  constructor(public dialog: MatDialogRef<ModalActionComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private simulatorService: SimulatorService) {
    this.message = this.data.message
  }

  ngOnInit(): void {
  }

  startEvent() {
    this.dialog.close({eventParent: true})
  }

  deleteMission() {

  }
}
