import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {InlineSVGModule} from 'ng-inline-svg';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavbarComponent} from './layout/navbar/navbar.component';
import {SpinnerService} from "./shared/components/spinner/spinner.service";
import {SharedModule} from "./shared/shared.module";
import {GigyaService} from './_services/gigya/gigya.service';
import {AuthGuard} from "./_services/guards/auth.guard";
import {AuthenticationInterceptor} from "./_services/interceptors/authentication.interceptor";
import {SpinnerInterceptor} from "./_services/interceptors/spinner.interceptor";
import {ScriptInjectorService, ScriptKey} from './_services/script-injector-service';
import {UserProfileService} from "./_services/user-profile.service";
import {LayoutComponent} from './layout/layout.component';
import {GigyaGuard} from './_services/guards/gigya-guard';

const providers = [
  AuthGuard,
  GigyaService,
  GigyaGuard,
  UserProfileService,
  SpinnerService,
  { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true,},
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
  { provide: LocationStrategy, useClass: HashLocationStrategy },
  ScriptInjectorService,
  {
    provide: APP_INITIALIZER,
    useFactory: loadGigyaScript,
    deps: [ScriptInjectorService],
    multi: true
  }
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    InlineSVGModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers,
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.apiUrl}/labels/`, '');
}

export function loadGigyaScript(scriptInjector: ScriptInjectorService) {
  return (): Promise<any> => {
    return scriptInjector.loadScript(ScriptKey.gigya);
  }
}
