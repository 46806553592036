import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AlgorithmImprovement, BevVehicle} from "../../../_models/Algorithm";
import {AlgorithmService} from "../../../_services/algorithm.service";
import {SimulatorService} from "../../../_services/simulator.service";
import {MatDialog} from "@angular/material/dialog";
import {ModalActionComponent} from "../modal-action/modal-action.component";
import {UtilityService} from "../../../_services/utility.service";
import {SessionStoragerService} from "../../../_services/storager/session-storager.service";
import {InfoModalComponent} from "../info-modal/info-modal.component";

declare let $: any;

@Component({
  selector: 'vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss']
})
export class VehicleCardComponent {
  modalChecked: boolean = false;
  @Input() idMission: string = '';
  @Input() heatId: string;
  @Input() vehicle: BevVehicle = new BevVehicle();
  @Input() algorithm: AlgorithmImprovement = new AlgorithmImprovement();
  @Input() cardType: string = '';

  @Output() _vehicleChanged: EventEmitter<BevVehicle> = new EventEmitter<BevVehicle>();


  constructor(public dialog: MatDialog, private algorithmService: AlgorithmService,
              private simulatorService: SimulatorService, public utilityService: UtilityService,
              private storager: SessionStoragerService) {
    this.modalChecked = this.storager.getElement('infoModal');
  }

  changeVehicleVar() {
    if (this.vehicle.checkedBattery == 79) {
      if (this.vehicle.checkedCharger == 6.6) {
        this.vehicle.checkedCharger = 11;
      }
    }
    this.algorithm.battery = this.vehicle.checkedBattery
    this.algorithm.onboardCharging = this.vehicle.checkedCharger;
    this.algorithm.heatId = this.heatId;
    this.algorithm.bevKey = this.vehicle.bevKey;
    this.algorithm.gvw = parseFloat(this.algorithm.gvw);
    this.algorithmService.getElectricModelsSingle(this.algorithm).then((res) => {
      this.vehicle = Object.assign(this.vehicle, res);
    }).catch((err) => console.log('ERROR FIND SINGLE MODEL: ', err))
      .finally(() => {
        this._vehicleChanged.emit(this.vehicle)
      });
  }


  saveSimulation() {
    var body = this.simulatorService.generateBodyForSaveMission(this.algorithm, this.vehicle)
    this.simulatorService.saveSimulatedMission(body).then((res) => {
      this.simulatorService.callSimulatedMission.next(true);
      this.dialog.closeAll();
      this.openModal();
    });
  }

  openDialog(type) {
    var dialogRef = this.dialog.open(ModalActionComponent, {
      id: 'modal-action',
      data: {type: type, idMission: this.idMission}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['isFinish']) {
        this.simulatorService.callSimulatedMission.next(true);
      }
    });
  }

  activeRenameMission() {
    this.simulatorService.missionToEdit.next(this.idMission)
  }

  deleteMission() {
    const dialogRef = this.dialog.open(ModalActionComponent, {
      id: 'modal-action',
      data: {
        message: 'CONTINUE_ACTION',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result.eventParent) {
        this.simulatorService.deleteMission(this.idMission).then((res) => {
          console.log(res)
        }).catch((error) => console.log(error)).finally(() => {
          this.simulatorService.callSimulatedMission.next(true);
        });
      }
    });
  }

  openModal() {
    var self = this;
    if (!this.modalChecked) {
      setTimeout(function () {
        const dialogRef = self.dialog.open(InfoModalComponent, {
          id: 'journeyInfo'
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log(`Dialog result: ${result}`);
        });
      }, 1000);
    }
  }

}
