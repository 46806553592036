import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Algorithm} from "../../../_models/Algorithm";

@Component({
  selector: 'app-algorithm-changer',
  templateUrl: './algorithm-changer.component.html',
  styleUrls: ['./algorithm-changer.component.scss']
})
export class AlgorithmChangerComponent implements OnInit {

  @Input() algorithm: Algorithm = new Algorithm();
  @Input() maxPayload: number = 0;
  @Input() minPayload: number = 0;
  @Input() maxAvgPayload: number = 0;
  openPanel: boolean = false;

  @Output() onButtonClick: EventEmitter<Algorithm> = new EventEmitter<Algorithm>()

  @ViewChild("noUiSlider") rangeSlider: ElementRef;

  startRangeValues = [50, 90, 100]
  // slider range
  startingUrban = 50;
  startingExtra = 90;

  mix = {
    urban: this.startingUrban,
    extra: this.startingExtra,
    motorway: 100 - (this.startingUrban + this.startingExtra)
  };

  rangeConfig = {
    start: [this.startingUrban, this.startingExtra + this.startingUrban, 100],
    connect: [true, true, true, true],
    range: {
      min: [0],
      max: [100]
    },
    margin: 0,
    step: 1,
  };

  temperatureConfig = {
    autoTicks: false,
    disabled: false,
    invert: false,
    max: 30,
    min: -10,
    showTicks: false,
    step: 1,
    thumbLabel: false,
    vertical: false,
    tickInterval: 0
  }
  workDaysConfig = {
    autoTicks: false,
    disabled: false,
    invert: false,
    max: 7,
    min: 1,
    showTicks: false,
    step: 1,
    thumbLabel: true,
    vertical: false,
    tickInterval: 0
  }
  workWeeksConfig = {
    autoTicks: false,
    disabled: false,
    invert: false,
    max: 52,
    min: 1,
    showTicks: false,
    step: 1,
    thumbLabel: true,
    vertical: false,
    tickInterval: 0
  }

  constructor() {
  }

  ngOnInit(): void {
    this.startRangeValues = [(this.algorithm.urbanUse * 100),
      (this.algorithm.extraurbanUse * 100) + (this.algorithm.urbanUse * 100), 100];
    this.mix.urban = (this.algorithm.urbanUse * 100);
    this.mix.extra = (this.algorithm.extraurbanUse * 100);
    this.mix.motorway = (this.algorithm.highwayUse * 100);
  }

  checkPayloadInRange(event, payload) {
    if (payload < this.minPayload) {
      this.algorithm.payload = this.minPayload;
    }
    if (payload > this.maxPayload) {
      this.algorithm.payload = this.maxPayload;
    }
  }

  checkAvgPayloadInRange(event, payload) {
    if (payload < 0) {
      this.algorithm.avgPayload = 0;
    }
    if (payload > this.maxPayload) {
      this.algorithm.avgPayload = this.maxPayload;
    }
  }

  toggleOpenPane() {
    this.openPanel = !this.openPanel;
  }

  onRangeChange($values) {
    const [urban, extraVal] = $values;
    const extra = extraVal - urban;
    let motorway = 100 - urban - extra;
    this.mix.urban = urban;
    this.mix.extra = extra;
    this.mix.motorway = motorway;
  }

  onEndSlide(event) {

  }

  eventFinish() {
    this.openPanel = false;
    this.algorithm.urbanUse = (this.mix.urban / 100)
    this.algorithm.extraurbanUse = (this.mix.extra / 100)
    this.algorithm.highwayUse = (this.mix.motorway / 100)
    this.onButtonClick.emit(this.algorithm);
  }

  close() {
    this.openPanel = false;
  }

}
