<div mat-dialog-title>
  <h2 class="boldWhite">{{'CHOOSE_ALTERNATIVES'|translate}}</h2>
  <!--  <h4 class="boldPrimary mb-0">{{'CODE_IDENTIFIER'|translate}}</h4>-->
</div>
<mat-dialog-content class="mat-typography">
  <div (click)="closeDialog()" class="btn-dialog-close" [inlineSVG]="'/assets/icons/close-modal.svg'"></div>
  <br>
  <br>
  <div *ngFor="let vehic of dataToView" class="row mb-3">
    <div id="alternatives-modal" class="col-12">
      <vehicle-group-view cardType="modalView" [idMission]="idMission" [vehicleCode]="vehicleCode" [vehicle]="vehic" [background]="'#2E586E'" [showSave]="true"></vehicle-group-view>
    </div>
  </div>
</mat-dialog-content>
<!--<div class="row justify-content-center align-items-center">-->
<!--  <img class="btn-table-prev mr-2" [ngClass]="{'disabled': actualPage <= 1}" (click)="prevPage()"-->
<!--       src="/assets/icons/left-arrow.svg">-->
<!--  <h3 class="boldWhite mb-0">{{actualPage}}/{{lastPage}}</h3>-->
<!--  <img class="btn-table-next ml-2" [ngClass]="{'disabled': actualPage >= lastPage}" (click)="nextPage()"-->
<!--       src="/assets/icons/left-arrow.svg">-->
<!--</div>-->
