<div class="overlay-changer-data-mission" [ngClass]="{'overlay-open': openPanel}" (click)="close()"></div>
<div class="row changer-data-mission" [ngClass]="{'open': openPanel}">
  <div class="btn-open-mission" (click)="toggleOpenPane()">
    <div class="icon" [inlineSVG]="'/assets/icons/three_arrow.svg'"></div>
  </div>
  <div class="col-12 content-changer-data-mission" [ngClass]="{'open': openPanel}">
    <div class="pl-4 pb-4 pt-4 pr-0">
      <div class="card my-2">
        <div class="row">
          <div class="col-md-12 justify-content-center boldWhite">
            {{'DAILY_MILEAGE' | translate}} (km)
            <div class="row">
              <div class="col-md-12 justify-content-center mt-3">
                <div class="position-relative">
                  <input
                    type="number"
                    class="formInput w-100 text-center"
                    placeholder="{{'INSERT_VALUE' | translate}}"
                    [(ngModel)]="algorithm.km"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3 justify-content-center boldWhite">
            {{'DAY_WORK_WEEK' | translate}}
            <div class="row">
              <div class="col-md-12 mt-3">
                <div class="row align-items-center">
                  <div class="col-12 d-flex">
                    <span class="boldWhite"> {{ workDaysConfig.min }}</span>
                    <div class="wrapper w-100">
                      <mat-slider
                        class="w-100 slider-flat"
                        [disabled]="workDaysConfig.disabled"
                        [invert]="workDaysConfig.invert"
                        [max]="workDaysConfig.max"
                        [min]="workDaysConfig.min"
                        [step]="workDaysConfig.step"
                        [thumbLabel]="workDaysConfig.thumbLabel"
                        [tickInterval]="workDaysConfig.tickInterval"
                        [(ngModel)]="algorithm.weekWorkingDays"
                        [vertical]="workDaysConfig.vertical"
                      >
                      </mat-slider>
                    </div>
                    <span class="boldWhite"> {{ workDaysConfig.max }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3 justify-content-center boldWhite">
            {{'WEEK_WORK_YEAR' | translate}}
            <div class="row">
              <div class="col-md-12 mt-3">
                <div class="row align-items-center">
                  <div class="col-12 d-flex">
                    <span class="boldWhite"> {{ workWeeksConfig.min }}</span>
                    <div class="wrapper w-100">
                      <mat-slider
                        class="w-100 slider-flat"
                        [disabled]="workWeeksConfig.disabled"
                        [invert]="workWeeksConfig.invert"
                        [max]="workWeeksConfig.max"
                        [min]="workWeeksConfig.min"
                        [step]="workWeeksConfig.step"
                        [thumbLabel]="workWeeksConfig.thumbLabel"
                        [tickInterval]="workWeeksConfig.tickInterval"
                        [(ngModel)]="algorithm.yearWorkingWeeks"
                        [vertical]="workWeeksConfig.vertical"
                      >
                      </mat-slider>
                    </div>
                    <span class="boldWhite"> {{ workWeeksConfig.max }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-12 mt-3">

      </div>
      <div class="card my-2">
        <div class="row mb-2">
          <div class="col-6" [ngClass]="{'disabled': algorithm.bodyType == 'PEOPLE'}">
            <label class="boldWhite">{{'LOAD' | translate}}</label>
            <input
              type="number"
              [min]="minPayload"
              class="formInput w-100 text-center"
              placeholder="{{'INSERT_VALUE' | translate}}"
              [(ngModel)]="algorithm.payload"
              [max]="maxPayload"
              (focusout)="checkPayloadInRange($event,algorithm.payload)"
            />
            <!--          <div class="row my-1 justify-content-around position-relative">-->
            <!--            <p class="boldWhite m-0">Min: {{minPayload}}kg</p>-->
            <!--            <p class="boldWhite m-0">Max: {{maxPayload}}kg</p>-->
            <!--          </div>-->
          </div>
          <div class="col-6" [ngClass]="{'disabled': algorithm.bodyType == 'PEOPLE'}">
            <label class="boldWhite">{{'AVERAGE_CARGO' | translate}} (kg)</label>
            <input
              type="number"
              [min]="0"
              class="formInput w-100 text-center"
              placeholder="{{'INSERT_VALUE' | translate}}"
              [(ngModel)]="algorithm.avgPayload"
              [max]="maxPayload"
              (focusout)="checkAvgPayloadInRange($event,algorithm.avgPayload)"
            />
            <!--            <div class="row my-1 justify-content-around position-relative">-->
            <!--              <p class="boldWhite m-0">Min: {{minPayload}}kg</p>-->
            <!--              <p class="boldWhite m-0">Max: {{maxPayload}}kg</p>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <label class="boldWhite">{{'FUEL_COST' | translate}}</label>
            <input
              type="number"
              [min]="0"
              class="formInput w-100 text-center"
              placeholder="{{'INSERT_VALUE' | translate}}"
              [(ngModel)]="algorithm.fuelDieselCost"
              step="0.01"
            />
            <!--          <div class="row my-1 justify-content-around position-relative">-->
            <!--            <p class="boldWhite m-0">Min: {{minPayload}}kg</p>-->
            <!--            <p class="boldWhite m-0">Max: {{maxPayload}}kg</p>-->
            <!--          </div>-->
          </div>
          <div class="col-6">
            <label class="boldWhite">{{'ELECTRICITY_COST' | translate}} (h)</label>
            <input
              type="number"
              [min]="0"
              step="0.01"
              class="formInput w-100 text-center"
              placeholder="{{'INSERT_VALUE' | translate}}"
              [(ngModel)]="algorithm.fuelBevCost"
            />
            <!--            <div class="row my-1 justify-content-around position-relative">-->
            <!--              <p class="boldWhite m-0">Min: {{minPayload}}kg</p>-->
            <!--              <p class="boldWhite m-0">Max: {{maxPayload}}kg</p>-->
            <!--            </div>-->
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <label class="boldWhite">{{'LONG_RECHARGE' | translate}}</label>
            <input
              type="number"
              class="formInput w-100 text-center"
              placeholder="{{'INSERT_VALUE' | translate}}"
              [(ngModel)]="algorithm.longChargingSlot"
              step="0.25"
              min="0"
            />
            <!--          <div class="row my-1 justify-content-around position-relative">-->
            <!--            <p class="boldWhite m-0">Min: {{minPayload}}kg</p>-->
            <!--            <p class="boldWhite m-0">Max: {{maxPayload}}kg</p>-->
            <!--          </div>-->
          </div>
          <div class="col-6">
            <label class="boldWhite">{{'SHORT_RECHARGE' | translate}} (h)</label>
            <input
              type="number"
              class="formInput w-100 text-center"
              placeholder="{{'INSERT_VALUE' | translate}}"
              [(ngModel)]="algorithm.shortChargingSlot"
              step="0.25"
              min="0"
            />
            <!--            <div class="row my-1 justify-content-around position-relative">-->
            <!--              <p class="boldWhite m-0">Min: {{minPayload}}kg</p>-->
            <!--              <p class="boldWhite m-0">Max: {{maxPayload}}kg</p>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="card my-2">
        <div class="row">
          <div class="col-12">
            <div class="slider-degrees">
              <div>
                  <span>{{'USE_MIX' | translate}} (%)</span>
              </div>
              <div class="slider-degrees__values mb-2">
                <span>{{'URBAN' | translate}}:{{mix.urban | number: '1.0-0'}}</span>
                <span>{{'EXTRA_URBAN' | translate}}:{{mix.extra | number: '1.0-0'}}</span>
                <span>{{'MOTORWAY' | translate}}:{{mix.motorway | number: '1.0-0'}}</span>
              </div>
              <nouislider
                #noUiSlider
                class="slider-range"
                [(ngModel)]="startRangeValues"
                [config]="rangeConfig"
                (ngModelChange)="onRangeChange($event)"
              >
              </nouislider>
<!--              (end)="onEndSlide($event)"-->
            </div>
          </div>
          <div class="col-12">
            <div class="slider-degrees">
              <div>
                  <span>{{'EXTERNAL_TEMPERATURE' | translate}}
                    :</span><span>&nbsp;&nbsp;&nbsp;{{ algorithm.temperature }}
                °</span>
              </div>
              <div class="slider-degrees__values">
                <span>-10°</span>
                <!--              <span>0°</span>-->
                <!--              <span>10°</span>-->
                <!--              <span>20°</span>-->
                <span>30°</span>
              </div>
              <mat-slider
                [thumbLabel]="false"
                [min]="-10"
                [max]="30"
                [step]="1"
                [(ngModel)]="algorithm.temperature"
              ></mat-slider>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
  </div>
  <div class="col-12 py-3 text-center btn-finish">
    <button mat-flat-button color="primary" (click)="eventFinish()"
            class="boldWhite">{{'FIND_ELECTRIC_MODEL' | translate}}</button>
  </div>
</div>
