import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GigyaService} from '../gigya/gigya.service';


@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private gigya: GigyaService,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    /**
     * FUNZIONANTEEEEEEEEEEEE
     */
    console.log(`AuthGuard is checking login state for ${route}, e state ${state.url}`);
    return this.gigya.isLoggedIn().pipe(map(authenticated => {
      if (!authenticated) {
        console.log('Utente non loggato. Reindirizzo verso la pagina di login')
        this.router.navigate(['login']);
        return false;
      }
      return true;
    }), catchError(error => of(false)));

  }
}
